import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene3_4 extends Scene
{
    constructor() 
    {
      super();
         
      this.loader.add("assets/jinju_6.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {
   
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgokwork.jpg", true);
      view.addChild(bg);

       
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);


      ModelManager.instance().setTarget(3,4);
      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;
      
      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      icon_item.FadeLoopCount();

      view.addChild(icon_item);
      
      let msg="";
      if (target=="C3_4_A")
      {
          msg="그건 이따가 알려줄게!\n우리가 가져갈\n 붉은간토기는 이런\n 모양이야!";
      }

      if (target=="C3_4_B")
      {
          msg="그건 이따가 알려줄게!\n우리가 가져갈\n가지무늬토기에는 무늬가\n있고 이런 모양이야!";
      }
      
      let TextBox2= new TextBoxBottom("진주",msg);
      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);

      let startButton = new Button("좋아! 가자!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(3500);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }

} 

export {Scene3_4}
