import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { TextBox } from "./TextBox.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { sound } from '@pixi/sound';

// admin
class SceneUser extends Scene
{
    constructor() 
    {
        super();
      
        let  bg = new Image("bg-galaxy2.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        let bg_txt = new Image("txt-bg.png");
        super.addChild(bg_txt);

        bg_txt.x = Config.SCREEN_WIDTH/2;
        bg_txt.y = 334 + 365/2;


        let image_txt_line= new Image("image-txt-line.png");
        super.addChild(image_txt_line);

        image_txt_line.x = Config.SCREEN_WIDTH/2;
        image_txt_line.y = 461;


        let title= new PIXI.Text("관람객 정보", {
            fontFamily: "font1000",
            fontSize: 60,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
    
            title.anchor.set(0.5);
            title.x= Config.SCREEN_WIDTH/2;
            title.y= 420;
            super.addChild(title);
            

        let text= new PIXI.Text("본 관람객의 정보입니다.", {
        fontFamily: "font1000",
        fontSize: 40,
        fill: "#ffffff",
        stroke: "black",
        strokeThickness: 0,
        letterSpacing : 0,
        align : 'center'
        });

        text.anchor.set(0.5);
        text.x= Config.SCREEN_WIDTH/2;
        text.y= 498;
        super.addChild(text);

        
        let phoneNo = ModelManager.instance().getData("phoneNo");

        if ( phoneNo.length >=11)
        {
            let first_char = phoneNo.substr(0, 3);
            let second_char = phoneNo.substr(3, 4);
            let last_char = phoneNo.substr(7, 4);
            phoneNo = first_char +"-" + second_char+"-" +last_char;
        }

        let text2= new PIXI.Text(phoneNo, {
        fontFamily: "font1000",
        fontSize: 40,
        fill: "#ffffff",
        stroke: "black",
        strokeThickness: 0,
        letterSpacing : 0,
        align : 'center'
        });

        text2.anchor.set(0.5);
        text2.x= Config.SCREEN_WIDTH/2;
        text2.y= 590;
        super.addChild(text2);

        let startButton = new Button("확인",40);

        startButton.x = Config.SCREEN_WIDTH/2;
        startButton.y = 413+99+100+305;

        super.addChild(startButton);

        startButton.on('pointerup',()=>
        {
            SceneManager.instance().SetScene(SCENE.ARCHECKFAIL);
        }
        );
    }
		
   
} 

export {SceneUser}
