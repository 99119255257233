import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene2_3 extends Scene
{
    talker:EffectAnimation;

    constructor() 
    {
        super();


        this.loader.add("assets/farmer_7.json")
        .add("assets/farmer_6.json")
        .add("assets/farmer_1.json")
        .add("assets/hobot3.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.DoStart();
    
          }
        );

    }

    DoStart():void
    {
      sound.stopAll();
      sound.play('bgm3',{ loop:true, volume:0.3 });
      

      ModelManager.instance().setTarget(2,3);
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgfarm.jpg", true);
      view.addChild(bg);

      this.talker = new EffectAnimation(this.GetTexture("assets/farmer_7.json") );
      this.talker.x = 191+437/2;
      this.talker.y = 409+408/2; 
      view.addChild(this.talker);


      

      let TextBox1= new TextBox("새로운 친구구나!\n만나서 반가워.");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("안녕! 반가워",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
     
    
      let index:number=0;
      startButton.on('pointerup',()=>
      {
        index++;
        if (index==1)
        {

          this.talker.SetAni(this.GetTexture("assets/farmer_6.json") );

          TextBox1.init("오랜만에 손님이 왔는데…\n어제 침략이 있었어.\n그래서 대접할 것이 없네.\n내가 아끼던 도구도\n 잃어버렸어");
          startButton.SetText("이런, 내가 도와줄까?",30);
          startButton.DelayActive(TextBox1.GetTime());

        }else
        {
          super.removeChild(view);
          this.next2();
        }

      } );   
    }
    
    next2():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgfarm.jpg", true);
      view.addChild(bg);
      
    // 권유
      this.talker.SetAni(this.GetTexture("assets/farmer_1.json") );


      // let talker = new Image("farmer.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      view.addChild(this.talker);
      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;
      icon_item.FadeLoopCount();

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      view.addChild(icon_item);
      

      let TextBox2= new TextBoxBottom("농사꾼","그래줄 수 있어?\n그럼 이것 좀 찾아줄래?\n분명 이 주변에 있을거야!");
      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);

      let startButton = new Button("좋아, 한번 찾아볼게!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(3500);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next3();

      } );   
    }


    ///
    next3():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgfarm.jpg", true);
      view.addChild(bg);
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot3.json"),1 );


      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);

      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTargetHide(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      let TextBox1= new TextBox("아래 모양을 잘 보면\n쉽게 찾을 수 있을거야! ");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("알았어!",30 );


      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }
} 

export {Scene2_3}
