import * as PIXI from "pixi.js";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { sound } from '@pixi/sound';
class PopupErrorZone extends PIXI.Container 
{

    constructor( parent:Scene ) {
        super();
        
        let image = new Image("bg-03.jpg", true);
        super.addChild(image);

        let bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/rect_box.png"), 70, 70, 70, 70);
        bg.width = 504;
        bg.height = 356;
        
        bg.x = Config.SCREEN_WIDTH / 2 - 504/2;
        bg.y = 300 ;
        
        super.addChild(bg);

        let icon_x = new Image("icon-x.png");
        icon_x.y=378;

        super.addChild(icon_x);

        let textArea = new PIXI.Text("잘못된 리더기를\n접촉하셨습니다.", {
            fontFamily: "font1000",
            fontSize: 36,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            textArea.anchor.set(0.5);
            textArea.x= Config.SCREEN_WIDTH / 2;
            textArea.y= 456;
            super.addChild(textArea);
        
        let textArea2 = new PIXI.Text("약도를 확인해주세요", {
            fontFamily: "font1000",
            fontSize: 33,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
        
            textArea2.anchor.set(0.5);
            textArea2.x= Config.SCREEN_WIDTH / 2;
            textArea2.y= 570;
            super.addChild(textArea2);
        
                
          let startButton = new Button("확인",30);
          startButton.x = Config.SCREEN_WIDTH / 2;
          startButton.y = 750;
          startButton.on('pointerup',()=>
          {
                parent.removeChild(this);
          } );   
        
          super.addChild(startButton);
        
    }
    
} 


export {PopupErrorZone}