// import * as PIXI from "pixi.js";
// import { Ease, ease } from "pixi-ease"
// import {DataManager} from "./DataManager.ts";
// import {Image} from "./Image.ts";

// class TextBoxBottom extends PIXI.Container 
// {
//     title:string;
//     public text:string;

//     titleArea:PIXI.Text;
//     textArea:PIXI.Text;
//     time:number = 0;

//     bg : Image;

//     constructor(title:string, text:string, s:number = 30) {
//         super();
        
//         //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["txt-bg-orange.png"], 80, 80, 80, 80);

//         this.bg =new Image("txt-bg-orange.png");
//         this.bg.x=0;
//         this.bg.y=0;
//         this.addChild(this.bg);

//         let line =new Image("image-line-orange.png");
//         line.x =0;
//         line.y = -50;
//         this.addChild(line);

        
//         this.UpdatePlane(title, text, s );
//     }

//     public UpdatePlane(title:string ,text:string, s:number = 30): void 
//     {
//         this.time = 0;
//         this.title = title;
//         this.text = text;
        
//         //let splitted = text.split("\n"); 
//         // let max_length = 5; 
//         // for(let i:number =0;i< splitted.length;i++)
//         // {
//         //     max_length = Math.max( max_length ,splitted[i].length);
//         // }
        
//         // this.bg.width = max_length * s ;
//         // this.bg.height = splitted.length * s + 100;

//         // this.bg.x = -this.bg.width /2;
//         // this.bg.y = -this.bg.height /2;

//         this.width= this.bg.width;
//         this.height=  this.bg.height;


//         this.titleArea = new PIXI.Text(this.title, {
//             fontFamily: "font1000",
//             fontSize: 40,
//             fill: "#f3aa29",
//             stroke: "black",
//             strokeThickness: 0,
//             letterSpacing : 0,
//             align:"left"
//             });
      
//             this.titleArea.anchor.set(0.5);
//             this.titleArea.x= -170;
//             this.titleArea.y= -80;
//             //this.titleArea.width = 500;
//             super.addChild(this.titleArea);

            
//             ////
//          this.textArea = new PIXI.Text("", {
//          fontFamily: "font1000",
//          fontSize: s,
//          fill: "#ffffff",
//          stroke: "black",
//          strokeThickness: 0,
//          letterSpacing : 0,
//          align:"left"
//          });
   
//          this.textArea.anchor.set(0.0);
//          this.textArea.x= -220;
//          this.textArea.y= -43;
//          //this.textArea.width = 500;
//          //this.textArea.height = 126;
//          super.addChild(this.textArea);
//          this.Scale();

//          this.gt = 0;
//          const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: this.text.length * 100 , ease: 'linear' });  
//      }
     
//       public init(text:string, s:number = 30):void
//       {
//          super.removeChild(this.textArea);
//          this.UpdatePlane("",text,s);
//       }

//     //  public Update(t:number):void
//     //  {
//     //     if (this.time==0)
//     //     {
//     //         this.time = t/100;
//     //     }
        
//     //     let l:number = Math.min(this.text.length, t/100 - this.time );
//     //     l = Math.max(0, l);

//     //     this.textArea.text = this.text.substring(0,Math.floor(l));
//     //  }


//      public Scale(): void 
//      {
//         this.scale.x = 0.01;
//         this.scale.y = 0.01;
//          const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 500, ease: 'easeOutBack' });
//      }
  
//      _gt:number = 0;
//      public get gt():number {
//        return this._gt;
//      }
  
//      public set gt(t:number)
//      {
//        this._gt = t;
     
//        let l:number = Math.min(this.text.length, t* this.text.length  );
//         l = Math.max(0, l);
 
//         this.textArea.text = this.text.substring(0,Math.floor(l));
//      }

// } 


// export {TextBoxBottom}


import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import {DataManager} from "./DataManager.ts";
import {Image} from "./Image.ts";
import { sound } from '@pixi/sound';
import {Config} from "./Config.ts";

class TextBoxBottom extends PIXI.Container 
{
    title:string;
    public text:string;

    titleArea:PIXI.Text;
    textArea:PIXI.Text;
    time:number = 0;

    splitted:string[];
    bg : PIXI.NineSlicePlane;

    constructor(title:string, text:string, s:number = 30) {
        super();
        
        //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["txt-bg-orange.png"], 80, 80, 80, 80);
        this.bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/txt-bg-orange.png"), 80, 80, 80, 80);

        //this.bg =new Image("txt-bg-orange.png");
        this.bg.x=-564/2;
        this.bg.y=-260/2;


        this.bg.width = 564;
        this.bg.height = 260;


        this.addChild(this.bg);

        let line =new Image("image-line-orange.png");
        line.x =0;
        line.y = -50;
        this.addChild(line);

        
        this.UpdatePlane(title, text, s );
    }

    public UpdatePlane(title:string ,text:string, s:number = 30): void 
    {
        this.time = 0;
        this.title = title;
        this.text = text;
             
        this.splitted = text.split("\n"); 

        this.width= this.bg.width;
        this.height=  this.bg.height;


        this.titleArea = new PIXI.Text(this.title, {
            fontFamily: "font1000",
            fontSize: 40,
            fill: "#f3aa29",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align:"left"
            });
      
            this.titleArea.anchor.set(0.5);
            this.titleArea.x= -170;
            this.titleArea.y= -80;
            //this.titleArea.width = 500;
            super.addChild(this.titleArea);

            
            ////
         this.textArea = new PIXI.Text("", {
         fontFamily: "font1000",
         fontSize: s,
         fill: "#ffffff",
         stroke: "black",
         strokeThickness: 0,
         letterSpacing : 0,
         align:"left"
         });
   
         this.textArea.anchor.set(0.0);
         this.textArea.x= -220;
         this.textArea.y= -43;
         //this.textArea.width = 500;
         //this.textArea.height = 126;
         super.addChild(this.textArea);
         this.Scale();

         this.gt = 0;
         const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: this.splitted.length* Config.DELAY  , ease: 'linear' });  
     }
     
      public init(text:string, s:number = 30):void
      {
         super.removeChild(this.textArea);
         this.UpdatePlane("",text,s);
      }

   

     public Scale(): void 
     {
        this.scale.x = 0.01;
        this.scale.y = 0.01;
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 500, ease: 'easeOutBack' });
         eff1.once('complete', () => {
      
            sound.play('eff3');
         }  );

     }
  
     _gt:number = 0;
     public get gt():number {
       return this._gt;
     }
  
     public set gt(t:number)
     {
       this._gt = t;
     
       let l:number = Math.min( this.splitted.length, t* this.splitted.length );
       l = Math.max(0, l);

       let msg="";

       for(let i=0;i< l;i++)
       {
           if (i==0)
           {
             msg+=this.splitted[i];
           }else{
             msg+="\n"+this.splitted[i];

           }
            
       }

       this.textArea.text = msg;

     }

     public GetTime():number
     {
         return this.splitted.length * Config.DELAY + 500;
     }
     
} 


export {TextBoxBottom}