import * as PIXI from "pixi.js";

import { IScene } from "./IScene.ts";
import { sound } from '@pixi/sound';
class Scene extends PIXI.Container implements IScene
{

    public loading:boolean =true;
    public loader:PIXI.Loader= new PIXI.Loader();  

    constructor() {
        super();
        
    }


    public GetTexture(file:string): Array<PIXI.Texture>
    {
        let textures =new Array<PIXI.Texture>();
        for(var t in this.loader.resources[file].spritesheet.textures) 
        {
           textures.push(this.loader.resources[file].spritesheet.textures[t]);
        }

        return textures;
    }
} 


export {Scene}