import * as PIXI from "pixi.js";
import { WebfontLoaderPlugin } from "pixi-webfont-loader";
import { sound } from '@pixi/sound';
import {DataManager} from "../../lib/html5story/DataManager.ts";
import {Scene} from "./Scene.ts";
import {ModelManager} from "./ModelManager.ts";
import { SceneLoading} from "./SceneLoading.ts";
import { SceneLogin } from "./SceneLogin.ts";
import { SceneEnd } from "./SceneEnd.ts";
import { SceneARCheckFail } from "./SceneARCheckFail.ts";
import { SceneARCheckSuccess } from "./SceneARCheckSuccess.ts"; 
import { SceneNextItem} from "./SceneNextItem.ts"; // 다음 곳으로
import { SceneARNotFound } from "./SceneARNotFound.ts";
import { SceneTarget} from "./SceneTarget.ts"; // ar 타겟
import { Scene2_1 } from "./Scene2_1.ts";
import { Scene2_2 } from "./Scene2_2.ts";
import { Scene2_2_A } from "./Scene2_2_A.ts";
import { Scene2_3_A } from "./Scene2_3_A.ts";
import { Scene2_3 } from "./Scene2_3.ts";
import { Scene2_4 } from "./Scene2_4.ts";
import { SceneClear} from "./SceneClear.ts";
import { SceneZone1} from "./SceneZone1.ts";
import { SceneZone1_1} from "./SceneZone1_1.ts";
import { SceneZone2} from "./SceneZone2.ts";
import { Scene3_1 } from "./Scene3_1.ts";
import { Scene3_1_A } from "./Scene3_1_A.ts";
import { Scene3_2 } from "./Scene3_2.ts";
import { Scene3_2_A } from "./Scene3_2_A.ts";
import { Scene3_3 } from "./Scene3_3.ts";
import { Scene3_3_A } from "./Scene3_3_A.ts";
import { Scene3_4 } from "./Scene3_4.ts";
import { Scene3_4_A } from "./Scene3_4_A.ts";
import { SceneZone3} from "./SceneZone3.ts";
import { Scene4_1 } from "./Scene4_1.ts";
import { Scene4_1_A } from "./Scene4_1_A.ts";
import { Scene4_2 } from "./Scene4_2.ts";
import { Scene4_2_A } from "./Scene4_2_A.ts";
import { Scene4_3 } from "./Scene4_3.ts";
import { Scene4_3_A } from "./Scene4_3_A.ts";
import { SceneUser } from "./SceneUser.ts"; // 실패시 처리
import { SceneItemList } from "./SceneItemList.ts"; // 실패시 처리
import {Config} from "./Config.ts";

PIXI.Loader.registerPlugin(WebfontLoaderPlugin);
//
// alex-productions-happy-and-fun-background-music     유물화면
// game-victory-sound-effect    성공시
enum SCENE {
    LOADING,
    LOGIN,
    END,
    ARCHECKFAIL,
    ARCHECKSUCCESS,
    ARNOTFOUND,
    TARGET, //
    NEXTITEM,// 
    USER,
    ZONE1,
    ZONE1_1,
    SCENE2_1,
    SCENE2_2,
    SCENE2_2_A,
    SCENE2_3,
    SCENE2_3_A,
    SCENE2_4,
    ZONE2,
    SCENE3_1,
    SCENE3_1_A,
    SCENE3_2,
    SCENE3_2_A,
    SCENE3_3,
    SCENE3_3_A,
    SCENE3_4,
    SCENE3_4_A,
    SCENE4_1,
    SCENE4_1_A,
    SCENE4_2,
    SCENE4_2_A,
    SCENE4_3,
    SCENE4_3_A,
    ITEMLIST,
    CLEAR,
    ZONE3,

}

class SceneManager {

    private static _instance: SceneManager = null;
    public root: PIXI.Container = null;
    public scene: Scene = null;
    public app:PIXI.Application = null;
    
    public static instance(): SceneManager {
        if (SceneManager._instance === null) {
            SceneManager._instance = new SceneManager();
        }
        return SceneManager._instance;
    }

    constructor() {
        if (SceneManager._instance) {
            throw new Error("Error: Config instead of new.");
        }
        SceneManager._instance = this;

        this.root = new PIXI.Container();
        // 중앙정렬
        this.root.x=(window.innerWidth - 640*  window.innerHeight/  1136   ) /2  / window.innerHeight * Config.SCREEN_HEIGHT;

        /////
        let dark = new PIXI.Container();
        let darkImage = new PIXI.Graphics();
        darkImage.beginFill(0x000000);
        // darkImage.drawRect(-(window.innerWidth - 640*  window.innerHeight/  1136   ) /2,0, (window.innerWidth - 640*  window.innerHeight/  1136   ) /2, 1140);
        // darkImage.drawRect(640,0, (window.innerWidth - 640*  window.innerHeight/  1136   ) /2,  1140);
        // darkImage.drawRect(-(window.innerWidth - 640*  window.innerHeight/  1136   )*2 ,0, (window.innerWidth - 640*  window.innerHeight/  1136   )*2 , 1140);
        // darkImage.drawRect(640,0, (window.innerWidth - 640*  window.innerHeight/  1136   ) *2 ,  1140);

        darkImage.drawRect(-window.innerWidth  ,0, window.innerWidth , 1140);
        darkImage.drawRect(640,0, window.innerWidth ,  1140);

        darkImage.endFill();
        this.root.addChild(darkImage);

        this.SetScene(SCENE.LOADING);
        sound.add('bgm', './assets/Fluffing-a-Duck.mp3');
        sound.add('bgm_item', './assets/alex-productions-happy-and-fun-background-music.mp3');
        sound.add('bgm_result', './assets/game-victory-sound-effect.mp3');
        sound.add('click', './assets/button-09a.mp3');
        sound.add('star', './assets/13658_1459784994.mp3');
        sound.add('stamp', './assets/19059_WoodCrashImpactBi_PE1056805.mp3');
        sound.add('bgm1', './assets/bgm1.mp3');
        sound.add('bgm2', './assets/bgm2.mp3');
        sound.add('bgm3', './assets/bgm3.mp3');
        sound.add('bgm4', './assets/bgm4.mp3');
        sound.add('eff1', './assets/eff1.mp3');
        sound.add('eff2', './assets/eff2.mp3');
        sound.add('eff3', './assets/eff3.mp3');
        sound.add('eff4', './assets/eff4.mp3');
        sound.add('eff5', './assets/eff5.mp3');
        sound.add('eff6', './assets/eff6.mp3');
        sound.add('eff7', './assets/eff7.mp3');
        sound.add('eff81', './assets/eff81.mp3');
        sound.add('eff82', './assets/eff82.mp3');
        sound.add('eff9', './assets/eff9.mp3');
        sound.add('eff10', './assets/eff10.mp3');
        sound.add('eff11', './assets/eff11.mp3');
        sound.add('eff12', './assets/eff12.mp3');
        sound.add('eff13', './assets/eff13.mp3');
        sound.add('eff14', './assets/eff14.mp3');
        sound.add('eff15', './assets/eff15.mp3');
        //sound.play('bgm2',{ loop:true, volume:0.3 });

        window.history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                window.history.pushState(null, null, document.URL);
            });
    }
    
    public Init(play:SCENE):void{
                
            const app = new PIXI.Application({ backgroundColor: 0x000000,
                width: window.innerWidth ,
                height:window.innerHeight,
                antialias:true,
            });
        
            app.stage.scale.set( window.innerHeight/  1136 );

            document.body.appendChild(app.view);
            let r = Math.random();

            var THIS=this;
            function setup() {
                app.stage.addChild(SceneManager.instance().root);
                THIS.app = app;
                    
                THIS.SetScene(play);
            }

            DataManager.instance().loader
            .add({ name: "font1000", url: "./assets/font1000.ttf" })
            .load(setup);

            console.log(window.location.href);

    }

    public SetScene(s:SCENE):void
    {
        
        console.log("SetScene:"+s+":"+SCENE[s]);
        if (this.scene!=null)
        {
            this.root.removeChild(this.scene);
        }

        if (s!=SCENE.LOADING)
        {
           let lastscene:string = s.toString();     

           ModelManager.instance().setData("lastscene",lastscene);
        }

        switch(s)
        {
            case SCENE.LOADING:
                this.scene = new SceneLoading();
                break;
            case SCENE.LOGIN:
                this.scene = new SceneLogin();
                break;
            case SCENE.TARGET:
                this.scene = new SceneTarget();
                break;
            case SCENE.END:
                this.scene = new SceneEnd();
                break;
            case SCENE.ARCHECKFAIL:
                this.scene = new SceneARCheckFail();
                break;
            case SCENE.ARCHECKSUCCESS:
                this.scene = new SceneARCheckSuccess();
                break;
            case SCENE.ARNOTFOUND:
                this.scene = new SceneARNotFound();
                break;
            case SCENE.USER:
                this.scene = new SceneUser();
                break;
            case SCENE.NEXTITEM:
                this.scene = new SceneNextItem();
                break;
            case SCENE.ZONE1:
                this.scene = new SceneZone1();
                break;
            case SCENE.ZONE1_1:
                this.scene = new SceneZone1_1();
                break;
            case SCENE.SCENE2_1:
                this.scene = new Scene2_1();
                break;
            case SCENE.SCENE2_2:
                this.scene = new Scene2_2();
                break;
            case SCENE.SCENE2_2_A:
                this.scene = new Scene2_2_A();
                break;
            case SCENE.SCENE2_3_A:
                this.scene = new Scene2_3_A();
                break;
            case SCENE.SCENE2_3:
                this.scene = new Scene2_3();
                break;
            case SCENE.SCENE2_4:
                this.scene = new Scene2_4();
                break;
            case SCENE.CLEAR:
                this.scene = new SceneClear();
                break;
            case SCENE.ZONE2:
                this.scene = new SceneZone2();
                break;
            case SCENE.SCENE3_1:
                this.scene = new Scene3_1();
                break;
            case SCENE.SCENE3_1_A:
                this.scene = new Scene3_1_A();
                break;
            case SCENE.SCENE3_2:
                this.scene = new Scene3_2();
                break;
            case SCENE.SCENE3_2_A:
                this.scene = new Scene3_2_A();
                break;
            case SCENE.SCENE3_3:
                this.scene = new Scene3_3();
                break;
            case SCENE.SCENE3_3_A:
                this.scene = new Scene3_3_A();
                break;
            case SCENE.SCENE3_4:
                this.scene = new Scene3_4();
                break;
            case SCENE.SCENE3_4_A:
                this.scene = new Scene3_4_A();
                break;
            case SCENE.ZONE3:
                this.scene = new SceneZone3();
                break;
            case SCENE.SCENE4_1:
                this.scene = new Scene4_1();
                break;
            case SCENE.SCENE4_1_A:
                this.scene = new Scene4_1_A();
                break;
            case SCENE.SCENE4_2:
                this.scene = new Scene4_2();
                break;
    
            case SCENE.SCENE4_2_A:
                this.scene = new Scene4_2_A();
                break;
            case SCENE.SCENE4_3:
                this.scene = new Scene4_3();
                break;

            case SCENE.SCENE4_3_A:
                this.scene = new Scene4_3_A();
                break;
            case SCENE.ITEMLIST:
                this.scene = new SceneItemList();
                break;
    
        }

        this.root.addChildAt(this.scene, 0);
    }

    public goURL(url:string):void
    {
        const urlParameter = window.location.search;
        window.location.href = url + urlParameter;


    // //const url = window.location.href;
    // const urlParameter = window.location.search;
    // const urlParams = new URLSearchParams(urlParameter);
    // console.log(urlParams.get("a"));
    // // for(const key of keys) {
    // //   console.log(key);
    // // }
    // // const values = urlParams.values();

    // // for(const value of values) {
    // //     console.log(value);
    // //   }
    }

} 

export {SceneManager, SCENE}