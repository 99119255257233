import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { TextBox } from "./TextBox.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
class SceneARCheckFail extends Scene
{
    TextBox1:TextBox;
    index:number=0;

    constructor() 
    {
        super();
        this.loader.add("assets/hobot2.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {

        let  bg = new Image("bg-galaxy2.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        let title = new PIXI.Text("AR인식 실패", {
            fontFamily: "font1000",
            fontSize: 60,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
        title.anchor.set(0.5);
        title.x=Config.SCREEN_WIDTH/2;
        title.y= 100 + 234/2;
        super.addChild(title);

        //this.TextBox1= new TextBox("흐음… 우리 다른 폰으로\n참여를 시도해 보는게 어떨까?", 30, false);
        this.TextBox1= new TextBox("흐음… 우리 다른 폰으로\n참여를 시도해 보는게 어떨까?", 30);
        this.TextBox1.x =Config.SCREEN_WIDTH/2;
        this.TextBox1.y = 286 + 30;
        super.addChild(this.TextBox1);
      

        let cha = new EffectAnimation(this.GetTexture("assets/hobot2.json"),1 );

        super.addChild(cha);
        cha.x = 500;
        cha.y = 700; 

        let phoneNo= ModelManager.instance().getData("phoneNo");

        console.log( phoneNo);

        if ( phoneNo=="" || phoneNo==undefined || phoneNo==null || phoneNo=="null")
        {
            console.log( phoneNo);
            const urlParameter = window.location.search;
            const urlParams = new URLSearchParams(urlParameter);
            phoneNo = urlParams.get("phoneNo");

            ModelManager.instance().setData("phoneNo",phoneNo);
            
        }

         let adminButton = new Button("____",30 );
         adminButton.x = 173/2;
         adminButton.y = 109/2;
         adminButton.alpha = 0;
         super.addChild(adminButton);
         
         adminButton.on('pointerup', ()=>
         {
           this.index++;
           console.log(this.index);
           if (this.index>=5)
           {
                SceneManager.instance().SetScene(SCENE.USER);
           }
         });
    }
		
    
} 

export {SceneARCheckFail}
