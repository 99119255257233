import {useEffect} from "react"

import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";

const ARCheckFail = (({location, match}) => {

  useEffect(() =>  {

    SceneManager.instance().Init(SCENE.ARCHECKFAIL );

  },[])

  return (
  <>
  </>
)
})

export {ARCheckFail}