import { useNavigate } from "react-router-dom"
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const DragDrop = (({arSceneStart, showNotFoundPopup, setShowNotFoundPopup, showDragDropBox, base, slug, dragViewList}) => {
	const navigate = useNavigate();

	const ddDropped = () => {
	  // console.log('dropped')
	}
	const ddHighlight = (event) => {
	  // console.log('highlight')

	  let arSuccessUrl = 'ARFind';
	  let arFailedUrl = 'ARNotFound';

	  if(dragViewList.indexOf(slug) != -1){
	    navigate(`${base}/${arSuccessUrl}`, { replace: true });
	  } else {
	    // ModelManager.instance().setData("errortarget",slug);
	    // navigate(`${base}/${arFailedUrl}`, { replace: true });

	    setShowNotFoundPopup(true)
	  }

	  // if(event.dragData.model == slug){
	  //   console.log('yesss')
	  // } else {
	  //   console.log('noo')
	  // }
	}
	const ddUnHighlight = () => {
	  // console.log('unHighlight')
	}


	if (arSceneStart && !showNotFoundPopup && showDragDropBox) {
	  return (
	    <>
	      
	      <div className="drag-drop-item-wrap">
	      	<img src={'/assets/images/8thwall-scene-page/target-images-output/drag-drop/top-text.png'} className='scene-top-img-title' />
	        
	        <DragDropContainer 
	          targetKey="foo" 
	          dragData={{'model': 1}}
	        >
	          <img width="70" src="/assets/images/8thwall-scene-page/target-images-output/drag-drop/C4_2_A/drag-item.png" />  
	        </DragDropContainer>

	        <DropTarget 
	          targetKey="foo"
	          onHit={ddDropped}
	          onDragEnter={ddHighlight}
	          onDragLeave={ddUnHighlight}
	        >
	          <img className="droppable" src="/assets/images/8thwall-scene-page/target-images-output/drag-drop/C4_2_A/drop-position-white-circle.png" />
	            
	        </DropTarget>
	      </div>
	    </>
	  )
	} else {
	  return false
	}
})

export {DragDrop}