// import * as PIXI from "pixi.js";

// import {DataManager} from "./DataManager.ts";
// import {Scene} from "./Scene.ts";
// import { Button } from "./Button.ts";
// import { Image } from "./Image.ts";
// import {Config} from "./Config.ts";
// import { SceneManager, SCENE } from "./SceneManager.ts";
// import {ModelManager} from "./ModelManager.ts";
// import { TextBox } from "./TextBox.ts";
// import { PopupErrorZone } from "./PopupErrorZone.ts";
// import {EffectAnimation} from "./EffectAnimation.ts";
// import { sound } from '@pixi/sound';

// class SceneZone1_1 extends Scene
// {
//     view0:PIXI.Container;
//     view1:PIXI.Container;
//     view2:PIXI.Container; 
//     view3:PIXI.Container; 
//     TextBox1:TextBox;
//     TextBox2:TextBox;

//     nIntervId:number;
//     nIntervIdToggle:number;
//     // p18
//     constructor() 
//     {
//         super();
				
//       this.loader.add("assets/hobot4.json")
//       .load(
//       ()=>{ 
//           this.loading=false;
//           this.start();
//       }
//       );

//     }

//     start():void
//     {

      
//         let  bg = new Image("zone2bg.jpg", true);
//         bg.x = Config.SCREEN_WIDTH/2;
//         bg.y = Config.SCREEN_HEIGHT/2; 
//         super.addChild(bg);

//         this.view0 = new PIXI.Container();
//         this.view1 = new PIXI.Container();
//         this.view2 = new PIXI.Container();
//         this.view3 = new PIXI.Container();
//         this.view1.visible = false;
//         this.view2.visible = false;
//         this.view3.visible = false;

//         super.addChild(this.view0);
//         super.addChild(this.view1);
//         super.addChild(this.view2);
//         super.addChild(this.view3);

        
//         let layer_2 = new Image("10.png");
//         this.view0.addChild(layer_2);
//         layer_2.x = Config.SCREEN_WIDTH/2;
//         layer_2.y = 400+300/2; 
//         layer_2.MoveSin(0.9,30);


        
//         let dandi = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );

//         this.view0.addChild(dandi);
//         dandi.x = 500;
//         dandi.y = 700; 



        
//         this.TextBox1= new TextBox("출발!\n분명 재미있고 유익한 여행이 될거야!\n타임베슬을 타고 함께 가보자!", 30);
//         this.TextBox1.x = Config.SCREEN_WIDTH/2;
//         this.TextBox1.y = 286;
//         this.view0.addChild(this.TextBox1);

//         let startButton = new Button("신난다!",30);
//         startButton.x = Config.SCREEN_WIDTH / 2;
//         startButton.y = 882+43;
//         startButton.DelayActive( this.TextBox1.GetTime());
//         this.view0.addChild(startButton);
        
//         startButton.on('pointerup',()=>
//         {
//           this.view0.visible=false;
//           this.view1.visible=true;

//           //this.TextBox2= new TextBox("<대평, 그 이전> 관에서\n영상을 시청한후\n다음장소에서 옥 목걸이를\n접촉해주세요", 30, false);

//           this.TextBox2= new TextBox("<대평, 그 이전> 관에서\n영상을 시청한후\n다음 장소에서 옥 목걸이를\n접촉해주세요", 30, false);
//           this.TextBox2.x = Config.SCREEN_WIDTH/2;
//           this.TextBox2.y = 286+200;
//           this.view1.addChild(this.TextBox2);

//         } );   

//         {
//           let  bg = new Image("zone2bg.jpg", true);
//           bg.x = Config.SCREEN_WIDTH/2;
//           bg.y = Config.SCREEN_HEIGHT/2; 
//           this.view1.addChild(bg);
  
//           let title = new PIXI.Text("떠나보자!", {
//               fontFamily: "font1000",
//               fontSize: 60,
//               fill: "#ffffff",
//               stroke: "black",
//               strokeThickness: 0,
//               letterSpacing : 0,
//               align : 'center'
//               });
        
//           title.anchor.set(0.5);
//           title.x=Config.SCREEN_WIDTH/2;
//           title.y= 100 + 234/2;
//           this.view1.addChild(title);
  
  
//           let startButton = new Button("다 보면 다음 관으로 가자!",30);
//           startButton.x = Config.SCREEN_WIDTH / 2;
//           startButton.y = 882+43;
//           startButton.DelayActive( this.TextBox1.GetTime());
//           this.view1.addChild(startButton);
          
//           startButton.on('pointerup',()=>
//           {
//             this.view1.visible=false;
//             this.view2.visible=true;
//             this.view3.visible=false;
          
//             this.nIntervIdToggle = setInterval( ()=>{ this.toggle() } , 3000);

//               let kiosk_no="";
              
//               this.nIntervId = setInterval( ()=>{ 
//                   ModelManager.instance().getLocation().then((res)=>{
//                   console.log("getWeb"+JSON.stringify(res));

//                   if ( res.code == 200 )
//                   {
//                     if (res.data.kiosk_no=="2")
//                     {
//                       sound.play('eff4');
//                       clearInterval( this.nIntervId);
//                       clearInterval( this.nIntervIdToggle);
                      
//                       SceneManager.instance().SetScene(SCENE.SCENE2_1);

//                     }else
//                     {

//                       if (kiosk_no!="" && kiosk_no != res.data.kiosk_no)
//                       {
//                           let popupErrorZone = new PopupErrorZone(this);
//                           super.addChild(popupErrorZone);
//                           //let info=new Image("zone2.jpg");
//                           //info.x = Config.SCREEN_WIDTH/2;
//                           //info.y = Config.SCREEN_HEIGHT/2; 
//                           //super.addChild(info);
//                       }

//                       kiosk_no = res.data.kiosk_no;
//                     }
//                   }
//                   // 
//                 });
//               }
//               , 2000);
  
//           } );   
  
//         }

//         //////////////////////////
//         {
//         let  bg = new Image("zone2bg.jpg", true);
//         bg.x = Config.SCREEN_WIDTH/2;
//         bg.y = Config.SCREEN_HEIGHT/2; 
//         this.view2.addChild(bg);

//         let map_dummy=new Image("mapbg.png");

//         map_dummy.x = Config.SCREEN_WIDTH/2;
//         map_dummy.y = 483 + 205/2; 
        
//         this.view2.addChild(map_dummy);

//         let map_dummy2=new Image("map2.png");
//         // map_dummy2.x = Config.SCREEN_WIDTH/2 - 104;
//         // map_dummy2.y = 483 + 205/2 - 49; 

//         map_dummy2.x = Config.SCREEN_WIDTH/2 - 103;
//         map_dummy2.y = 483 + 205/2 - 54; 

//         map_dummy2.ScaleLoop();

//         //let colorMatrix = new PIXI.filters.ColorMatrixFilter();
//         //map_dummy2.filters = [colorMatrix];
//         //colorMatrix.contrast(2, true);

        
//         this.view2.addChild(map_dummy2);

//         // let map_dummy3=new Image("map3.png");
//         // map_dummy3.x = Config.SCREEN_WIDTH/2 +16;
//         // map_dummy3.y = 483 + 205/2 - 109; 
        
//         // this.view2.addChild(map_dummy3);
 

//         // let map_dummy4=new Image("map4.png");
//         // map_dummy4.x = Config.SCREEN_WIDTH/2+150;
//         // map_dummy4.y = 483 + 205/2 - 105; 
        
//         // this.view2.addChild(map_dummy4);
 


//         // let arrow=new Image("image-arrow.png");

//         // arrow.x = Config.SCREEN_WIDTH/2;
//         // arrow.y = 655+ 59/2; 
//         // arrow.MoveSin(1, 10);
        
//         // this.view2.addChild(arrow);

//         let text2 = new PIXI.Text("영상 시청이 끝나셨군요!\n2관 풍요, 농경생활로 이동후", {
//           fontFamily: "font1000",
//           fontSize: 40,
//           fill: "#ffffff",
//           stroke: "black",
//           strokeThickness: 0,
//           letterSpacing : 0,
//           align : 'center'
//           });

//         text2.anchor.set(0.5);
//         text2.x= Config.SCREEN_WIDTH/2;
//         text2.y= 250; 
//         this.view2.addChild(text2);
//         }
//         /////////

//         {
//           let  bg = new Image("zone2bg.jpg", true);
//           bg.x = Config.SCREEN_WIDTH/2;
//           bg.y = Config.SCREEN_HEIGHT/2; 
//           this.view3.addChild(bg);

//           let text1 = new PIXI.Text("옥 목걸이를\n접촉해 주세요!", {
//             fontFamily: "font1000",
//             fontSize: 40,
//             fill: "#ffffff",
//             stroke: "black",
//             strokeThickness: 0,
//             letterSpacing : 0,
//             align : 'center'
//             });
      
//             text1.anchor.set(0.5);
//             text1.x= Config.SCREEN_WIDTH/2;
//             text1.y= 705+50; 
//             this.view3.addChild(text1);
  
  
//               // 옥
//             let image_01_copy=new Image("image-01-copy.png");
    
//             image_01_copy.x = Config.SCREEN_WIDTH/2;
//             image_01_copy.y = 349 + 305/2; 
    
//             image_01_copy.MoveSin();
            
//             this.view3.addChild(image_01_copy);

//           }

//           if (Config.SKIP==true)
//           {
//             sound.play('eff4');
//             let nextButton = new Button("____",30 );
//             nextButton.x = 173/2;
//             nextButton.y = Config.SCREEN_HEIGHT - 109/2;
//             nextButton.alpha = 0;
//             super.addChild(nextButton);
  
//             nextButton.on('pointerup', ()=>
//             {
//               clearInterval(this.nIntervId);
//               clearInterval(this.nIntervIdToggle);
//               SceneManager.instance().SetScene(SCENE.SCENE2_1);

              
//             }
//             );   
//           }


//           //
//     }
    
//     public toggle():void
//     {
//       sound.play('eff6');
//       this.view2.visible = !this.view2.visible;
//       this.view3.visible = !this.view3.visible;
//     }


// } 

// export {SceneZone1_1}


import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { TextBox } from "./TextBox.ts";
import { PopupErrorZone } from "./PopupErrorZone.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';

class SceneZone1_1 extends Scene
{
    view0:PIXI.Container;
    view1:PIXI.Container;
    view2:PIXI.Container; 
    view3:PIXI.Container; 
    TextBox1:TextBox;
    TextBox2:TextBox;

    nIntervId:number;
    nIntervIdToggle:number;
    // p18
    constructor() 
    {
        super();
				
      this.loader.add("assets/hobot4.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

    }

    start():void
    {

      
        let  bg = new Image("zone2bg.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        this.view0 = new PIXI.Container();
        this.view1 = new PIXI.Container();
        this.view2 = new PIXI.Container();
        this.view3 = new PIXI.Container();
        this.view1.visible = false;
        this.view2.visible = false;
        this.view3.visible = false;

        super.addChild(this.view0);
        super.addChild(this.view1);
        super.addChild(this.view2);
        super.addChild(this.view3);

        
        let layer_2 = new Image("10.png");
        this.view0.addChild(layer_2);
        layer_2.x = Config.SCREEN_WIDTH/2;
        layer_2.y = 400+300/2; 
        layer_2.MoveSin(0.9,30);


        
        let dandi = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );

        this.view0.addChild(dandi);
        dandi.x = 500;
        dandi.y = 700; 



        
        this.TextBox1= new TextBox("출발!\n분명 재미있고 유익한 여행이 될거야!\n타임베슬을 타고 함께 가보자!", 30);
        this.TextBox1.x = Config.SCREEN_WIDTH/2;
        this.TextBox1.y = 286;
        this.view0.addChild(this.TextBox1);

        let startButton = new Button("신난다!",30);
        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+43;
        startButton.DelayActive( this.TextBox1.GetTime());
        this.view0.addChild(startButton);
        
        startButton.on('pointerup',()=>
        {
          this.view0.visible=false;
          this.view1.visible=true;

          //this.TextBox2= new TextBox("<대평, 그 이전> 관에서\n영상을 시청한후\n다음장소에서 옥 목걸이를\n접촉해주세요", 30, false);

          this.TextBox2= new TextBox("<대평, 그 이전> 관에서\n영상을 시청한후\n다음 장소에서 옥 목걸이를\n접촉해주세요", 30, false);
          this.TextBox2.x = Config.SCREEN_WIDTH/2;
          this.TextBox2.y = 286+200;
          this.view1.addChild(this.TextBox2);



          
          let startButton = new Button("다 보면 다음 관으로 가자!",30);
          startButton.x = Config.SCREEN_WIDTH / 2;
          startButton.y = 882+43;
          startButton.DelayActive( this.TextBox2.GetTime());
          this.view1.addChild(startButton);
          
          startButton.on('pointerup',()=>
          {
            this.view1.visible=false;
            this.view2.visible=true;
            this.view3.visible=false;
          
            this.nIntervIdToggle = setInterval( ()=>{ this.toggle() } , 3000);

              let kiosk_no="";
              
              this.nIntervId = setInterval( ()=>{ 
                  ModelManager.instance().getLocation().then((res)=>{
                  console.log("getWeb"+JSON.stringify(res));

                  if ( res.code == 200 )
                  {
                    if (res.data.kiosk_no=="2")
                    {
                      sound.play('eff4');
                      clearInterval( this.nIntervId);
                      clearInterval( this.nIntervIdToggle);
                      
                      SceneManager.instance().SetScene(SCENE.SCENE2_1);

                    }else
                    {

                      if (kiosk_no!="" && kiosk_no != res.data.kiosk_no)
                      {
                          let popupErrorZone = new PopupErrorZone(this);
                          super.addChild(popupErrorZone);
                          //let info=new Image("zone2.jpg");
                          //info.x = Config.SCREEN_WIDTH/2;
                          //info.y = Config.SCREEN_HEIGHT/2; 
                          //super.addChild(info);
                      }

                      kiosk_no = res.data.kiosk_no;
                    }
                  }
                  // 
                });
              }
              , 2000);
  
          } );   
  

        } );   

        {
          let  bg = new Image("zone2bg.jpg", true);
          bg.x = Config.SCREEN_WIDTH/2;
          bg.y = Config.SCREEN_HEIGHT/2; 
          this.view1.addChild(bg);
  
          let title = new PIXI.Text("떠나보자!", {
              fontFamily: "font1000",
              fontSize: 60,
              fill: "#ffffff",
              stroke: "black",
              strokeThickness: 0,
              letterSpacing : 0,
              align : 'center'
              });
        
          title.anchor.set(0.5);
          title.x=Config.SCREEN_WIDTH/2;
          title.y= 100 + 234/2;
          this.view1.addChild(title);
  
  
        }

        //////////////////////////
        {
        let  bg = new Image("zone2bg.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        this.view2.addChild(bg);

        let map_dummy=new Image("mapbg.png");

        map_dummy.x = Config.SCREEN_WIDTH/2;
        map_dummy.y = 483 + 205/2; 
        
        this.view2.addChild(map_dummy);

        let map_dummy2=new Image("map2.png");
        // map_dummy2.x = Config.SCREEN_WIDTH/2 - 104;
        // map_dummy2.y = 483 + 205/2 - 49; 

        map_dummy2.x = Config.SCREEN_WIDTH/2 - 103;
        map_dummy2.y = 483 + 205/2 - 54; 

        map_dummy2.ScaleLoop();

        //let colorMatrix = new PIXI.filters.ColorMatrixFilter();
        //map_dummy2.filters = [colorMatrix];
        //colorMatrix.contrast(2, true);

        
        this.view2.addChild(map_dummy2);

        // let map_dummy3=new Image("map3.png");
        // map_dummy3.x = Config.SCREEN_WIDTH/2 +16;
        // map_dummy3.y = 483 + 205/2 - 109; 
        
        // this.view2.addChild(map_dummy3);
 

        // let map_dummy4=new Image("map4.png");
        // map_dummy4.x = Config.SCREEN_WIDTH/2+150;
        // map_dummy4.y = 483 + 205/2 - 105; 
        
        // this.view2.addChild(map_dummy4);
 


        // let arrow=new Image("image-arrow.png");

        // arrow.x = Config.SCREEN_WIDTH/2;
        // arrow.y = 655+ 59/2; 
        // arrow.MoveSin(1, 10);
        
        // this.view2.addChild(arrow);

        let text2 = new PIXI.Text("영상 시청이 끝나셨군요!\n2관 풍요, 농경생활로 이동후", {
          fontFamily: "font1000",
          fontSize: 40,
          fill: "#ffffff",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
          });

        text2.anchor.set(0.5);
        text2.x= Config.SCREEN_WIDTH/2;
        text2.y= 250; 
        this.view2.addChild(text2);
        }
        /////////

        {
          let  bg = new Image("zone2bg.jpg", true);
          bg.x = Config.SCREEN_WIDTH/2;
          bg.y = Config.SCREEN_HEIGHT/2; 
          this.view3.addChild(bg);

          let text1 = new PIXI.Text("옥 목걸이를\n접촉해 주세요!", {
            fontFamily: "font1000",
            fontSize: 40,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            text1.anchor.set(0.5);
            text1.x= Config.SCREEN_WIDTH/2;
            text1.y= 705+50; 
            this.view3.addChild(text1);
  
  
              // 옥
            let image_01_copy=new Image("image-01-copy.png");
    
            image_01_copy.x = Config.SCREEN_WIDTH/2;
            image_01_copy.y = 349 + 305/2; 
    
            image_01_copy.MoveSin();
            
            this.view3.addChild(image_01_copy);

          }

          if (Config.SKIP==true)
          {
            sound.play('eff4');
            let nextButton = new Button("____",30 );
            nextButton.x = 173/2;
            nextButton.y = Config.SCREEN_HEIGHT - 109/2;
            nextButton.alpha = 0;
            super.addChild(nextButton);
  
            nextButton.on('pointerup', ()=>
            {
              clearInterval(this.nIntervId);
              clearInterval(this.nIntervIdToggle);
              SceneManager.instance().SetScene(SCENE.SCENE2_1);

              
            }
            );   
          }


          //
    }
    
    public toggle():void
    {
      sound.play('eff6');
      this.view2.visible = !this.view2.visible;
      this.view3.visible = !this.view3.visible;
    }


} 

export {SceneZone1_1}

