// import * as PIXI from "pixi.js";
// import { Ease, ease } from "pixi-ease"
// import {DataManager} from "./DataManager.ts";
// import { sound } from '@pixi/sound';
// import {Config} from "./Config.ts";
// class TextBox extends PIXI.Container 
// {
//     public text:string;

//     textArea:PIXI.Text;
//     time:number = 0;

//     bg : PIXI.NineSlicePlane;

//     constructor(text:string, s:number = 30, IsBG:boolean=true) {
//         super();
        
//         if (IsBG)
//         {
            
//             //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["rect_box.png"], 70, 70, 70, 70);
//             this.bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/rect_box.png"), 70, 70, 70, 70);
//             //PIXI.Texture.from("./assets/images/scene/button.png"
//             this.addChild(this.bg);
//         }
        
//         //super.anchor.set(0.5);
//         this.UpdatePlane(text,s );
//     }

//     public UpdatePlane(text:string, s:number = 30): void 
//     {
//         //sound.play('eff7');

//         this.time = 0;
//         this.text = text;

        
//         let splitted = text.split("\n"); 

//         let max_length = 5; 
//         for(let i:number =0;i< splitted.length;i++)
//         {
//             max_length = Math.max( max_length ,splitted[i].length);
//         }

//         let ww = Math.min(600, max_length * s * 0.8 + 100);
//         ww = Math.max(150, ww);
//         ww = 600;
        
//         if (this.bg!=null)
//         {
//             sound.play('eff5');
//             this.bg.width = ww;
//             this.bg.height = splitted.length * s * 1.2 + 100;
    
//             this.bg.x = -this.bg.width /2;
//             this.bg.y = -this.bg.height /2;
    
//             this.width= this.bg.width;
//             this.height=  this.bg.height;
    
//         }else{
//             this.width= ww;
//             this.height=  splitted.length * s * 1.2 + 100;
//         }

//          this.textArea = new PIXI.Text("", {
//          fontFamily: "font1000",
//          fontSize: s,
//          fill: "#ffffff",
//          stroke: "black",
//          strokeThickness: 0,
//          letterSpacing : 0,
//          align : 'center'
//          });
   
//          this.textArea.anchor.set(0.5);
//          this.textArea.x= 0;
//          this.textArea.y= 0;
//          super.addChild(this.textArea);
//          this.Scale();


//          this.gt = 0;
//          const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: this.text.length * Config.DELAY , ease: 'linear' });        
         
//      }
     
//       public init(text:string, s:number = 30):void
//       {
//          super.removeChild(this.textArea);
//          this.UpdatePlane(text,s);
//       }

   

//      public Scale(): void 
//      {
//         this.scale.x = 0.01;
//         this.scale.y = 0.01;
//          const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 500, ease: 'easeOutBack' });
//      }


//      _gt:number = 0;
//     public get gt():number {
//       return this._gt;
//     }
 
//     public set gt(t:number)
//     {
//       this._gt = t;
    
//       let l:number = Math.min(this.text.length, t* this.text.length  );
//        l = Math.max(0, l);

       

//        this.textArea.text = this.text.substring(0,Math.floor(l));


//        if ( this.textArea.text.charAt(this.textArea.text.length-1) =="\n" )
//        {
           
//             //sound.play('eff7');
//        }
//     }

    

    
// } 


// export {TextBox}

import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import {DataManager} from "./DataManager.ts";
import { sound } from '@pixi/sound';
import {Config} from "./Config.ts";
class TextBox extends PIXI.Container 
{
    public text:string;

    textArea:PIXI.Text;
    time:number = 0;

    bg : PIXI.NineSlicePlane;
    splitted:string[];

    constructor(text:string, s:number = 30, IsBG:boolean=true) {
        super();
        
        if (IsBG)
        {
            
            //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["rect_box.png"], 70, 70, 70, 70);
            this.bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/rect_box.png"), 70, 70, 70, 70);
            //PIXI.Texture.from("./assets/images/scene/button.png"
            this.addChild(this.bg);
        }
        
        //super.anchor.set(0.5);
        this.UpdatePlane(text,s );
    }

    public UpdatePlane(text:string, s:number = 30): void 
    {
        //sound.play('eff7');

        this.time = 0;
        this.text = text;

        
        this.splitted = text.split("\n"); 

        let max_length = 5; 
        for(let i:number =0;i< this.splitted.length;i++)
        {
            max_length = Math.max( max_length ,this.splitted[i].length);
        }

        let ww = Math.min(600, max_length * s * 0.8 + 100);
        ww = Math.max(150, ww);
        ww = 600;
        
        if (this.bg!=null)
        {
            sound.play('eff5');
            this.bg.width = ww;
            this.bg.height = this.splitted.length * s * 1.2 + 100;
    
            this.bg.x = -this.bg.width /2;
            this.bg.y = -this.bg.height /2;
    
            this.width= this.bg.width;
            this.height=  this.bg.height;
    
        }else{
            this.width= ww;
            this.height=  this.splitted.length * s * 1.2 + 100;
        }

         this.textArea = new PIXI.Text("", {
         fontFamily: "font1000",
         fontSize: s,
         fill: "#ffffff",
         stroke: "black",
         strokeThickness: 0,
         letterSpacing : 0,
         align : 'center'
         });
   
         this.textArea.anchor.set(0.5);
         this.textArea.x= 0;
         this.textArea.y= 0;
         super.addChild(this.textArea);
         this.Scale();


         this.gt = 0;
         const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: this.splitted.length * Config.DELAY , ease: 'linear' });        
         
     }
     
      public init(text:string, s:number = 30):void
      {
         super.removeChild(this.textArea);
         this.UpdatePlane(text,s);
      }

   

     public Scale(): void 
     {
        this.scale.x = 0.01;
        this.scale.y = 0.01;
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 500, ease: 'easeOutBack' });
     }


     _gt:number = 0;
    public get gt():number {
      return this._gt;
    }
 
    public set gt(t:number)
    {
      this._gt = t;
    
       let l:number = Math.min( this.splitted.length, t* this.splitted.length );
       l = Math.max(0, l);

       let msg="";

       for(let i=0;i< l;i++)
       {
           if (i==0)
           {
             msg+=this.splitted[i];
           }else{
             msg+="\n"+this.splitted[i];

           }
            
       }

       this.textArea.text = msg;
       
    }

    public GetTime():number
    {
        return this.splitted.length * Config.DELAY + 500;
    }
    

    
} 


export {TextBox}