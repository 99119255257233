import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import {TextBox2} from "./TextBox2.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class Scene4_1_A extends Scene
{
    constructor() 
    {
      super();

      this.loader.add("assets/jinju_2.json")
      .add("assets/jinju_9.json")
      .add("assets/hobot4.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {
    sound.stopAll();
    sound.play('bgm1',{ loop:true, volume:0.3 });


      ModelManager.instance().setData("zone","C4");
     
    
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bg4_1.jpg", true);
      view.addChild(bg);

      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);
      // view.addChild(talker);
      

      let target = ModelManager.instance().getData("target");

if ( target=="C4_1_A")
{
  let talker = new EffectAnimation( this.GetTexture("assets/jinju_2.json") );
  talker.x = 191+437/2;
  talker.y = 509+408/2; 
  view.addChild(talker);

}else{
  let talker = new EffectAnimation( this.GetTexture("assets/jinju_9.json") );
  talker.x = 191+437/2;
  talker.y = 509+408/2; 
  view.addChild(talker);

}


  





      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;
      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      icon_item.FadeLoopCount();

      view.addChild(icon_item);
      
      // 123456789012345678
      //let TextBox1= new TextBox("와! 금방 찾았네!\n겹아가리 톱니무늬 바리는\n 점토를 눌러 접어 입구를\n 두겹으로 만들어. 입구에\n 톱니무늬를 선을 새기듯이\n 그려넣고 톱니 가운데에\n 점으로 포인트를 줬지!\n 꼭 밥그릇처럼 생기지\n 않았어?");
      //let TextBox1= new TextBox(Config.GetTargetDesc(target));      

      let TextBox1= new TextBox(Config.GetTargetDesc0(target));      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      setTimeout(()=>{
        TextBox1.init(Config.GetTargetDesc1(target));

        let startButton = new Button("그래 어서 가자",30 );

        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+93;
         
        startButton.DelayActive(TextBox1.GetTime());
  
  
        view.addChild(startButton);
      
        startButton.on('pointerup',()=>
        {
            //ModelManager.instance().setTarget(4,2);
            SceneManager.instance().SetScene(SCENE.SCENE4_2); // 
        } );   
       } ,TextBox1.GetTime());

      
    }

} 

export {Scene4_1_A}
