import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';

class Scene3_3_A extends Scene
{
    constructor() 
    {
      super();
         
      this.loader.add("assets/jinju_6.json")
      .add("assets/hobot3.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {

    sound.stopAll();
    sound.play('bgm3',{ loop:true, volume:0.3 });


      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgokwork.jpg", true);
      view.addChild(bg);

      
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot3.json") ,1);
      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);
      

      let TextBox1= new TextBox("옥공방 정말 멋있지 않니?");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);
      

      let startButton = new Button("그러게 말이야!",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);

      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next1();
      } );
    }

    next1():void
    {
      
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgokwork.jpg", true);
      view.addChild(bg);

      
      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);



      
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);







      let TextBox1= new TextBox("너, 나랑 같이 대평이네\n부모님 장례식에 가지\n않을래?\n토기를 좀 챙겨가야할 것\n같아");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("그래! 근데 토기는 왜?",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
      
      startButton.on('pointerup',()=>
      {
          //ModelManager.instance().setTarget(3,4);
          SceneManager.instance().SetScene(SCENE.SCENE3_4); // 
      } );   
    }

} 

export {Scene3_3_A}
