import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene2_2 extends Scene
{
    
    talker:EffectAnimation;
    constructor() 
    {
        super();
        
        this.loader.add("assets/cooker_5.json")
        .add("assets/cooker_6.json")
        .add("assets/cooker_2.json")
        .add("assets/cooker_3.json")
        .add("assets/hobot1.json")
        .add("assets/hobot3.json")
        .load(
          ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {
      sound.stopAll();
      sound.play('bgm3',{ loop:true, volume:0.3 });
      sound.play('eff10',{ loop:true, volume:0.1 });



        ModelManager.instance().setTarget(2,2);
      
        let view:PIXI.Container =new PIXI.Container();
        super.addChild(view);

        let bg = new Image("city.jpg", true);
        view.addChild(bg);
        
        let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot1.json"),1 );

        dandiimage.x = 386+212/2;
        dandiimage.y = 593+234/2-100; 
        //dandiimage.MoveSin(1, 30);

        view.addChild(dandiimage);

        //let TextBox1= new TextBox("청동기 시대에는 다들\n이렇게 살고 있나봐!");
        let TextBox1= new TextBox("청동기 시대의 크고\n작은 집들과 환호가 보이네");
        TextBox1.x =Config.SCREEN_WIDTH/2;
        TextBox1.y = 100 + 234/2;

        view.addChild(TextBox1);

        setTimeout(()=>sound.play('eff12'), 2000);

        let startButton = new Button("(쨍그랑!!)",30 );
        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+43;
        startButton.DelayActive(TextBox1.GetTime());
        view.addChild(startButton);
      
        let index:number=0;
        startButton.on('pointerup',()=>
        {
          if (super.loading==true)
            return;

          index++;
          if (index==1)
          {

            TextBox1.init("어디서 뭐가 깨지는\n소리 들리지 않았어?\n저쪽인 것 같은데...!");
            startButton.SetText("가보자!",30);
            startButton.DelayActive(TextBox1.GetTime() );

          }else if (index==2)
          {
            super.removeChild(view);
            this.next1();
          }

        } );   
    }

    next1():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgcook.jpg", true);
      view.addChild(bg);


      this.talker = new EffectAnimation( this.GetTexture("assets/cooker_6.json") );
      this.talker.x = 191+437/2;
      this.talker.y = 509+408/2; 
       view.addChild(this.talker);



      let TextBox1= new TextBox("아, 안녕! 처음보는 얼굴이네.");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("안녕",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
     
    
      let index:number=0;
      startButton.on('pointerup',()=>
      {
        index++;
        if (index==1)
        {
          this.talker.SetAni(this.GetTexture("assets/cooker_5.json") );

          //TextBox1.init("이제 곧 저녁을 먹어야 해서\n준비하고 있었는데 손이 미끄러\n워서 그릇이 깨져버렸네...");
          TextBox1.init("저녁 준비를 하다가\n그릇이 깨져 버렸어");

          startButton.SetText("괜찮니?",30);
          startButton.DelayActive(TextBox1.GetTime());

        }else if (index==2)
        {

          this.talker.SetAni(this.GetTexture("assets/cooker_2.json") );

          TextBox1.init("괜찮아. 치우면 되지 뭐!\n그나저나 요리를 계속하려면\n새로운 그릇이 필요한데\n그릇 깨진 것을 치워야 해서…\n혹시 네가 좀 구해다 줄 수 있어?");
          startButton.SetText("그래! 내가 도와줄게",30);
          startButton.DelayActive(TextBox1.GetTime());
          
        }else if (index==3)
        {
          super.removeChild(view);
          this.next2();
        }

      } );   
    }
    
    next2():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgcook.jpg", true);
      view.addChild(bg);

      
      
      let talker = new EffectAnimation(this.GetTexture("assets/cooker_3.json"));
       talker.x = 191+437/2;
       talker.y = 509+408/2; 
       view.addChild(talker);

     

      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      icon_item.FadeLoopCount();

      view.addChild(icon_item);
      

      let TextBox2= new TextBoxBottom("요리사","정말 고마워!\n아래 모양처럼 생긴\n토기를 찾아줄래?");
      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);


      // let startButton = new Button("좋아, 한번 찾아볼게!",30 );
      // startButton.x = Config.SCREEN_WIDTH / 2;
      // startButton.y = 882+93;
      // startButton.DelayActive(3500);
      // view.addChild(startButton);
    
      // startButton.on('pointerup',()=>
      // {
      //     super.removeChild(view);
      //     this.next3();

      // } );   

      setTimeout(()=>{
        super.removeChild(view);
        this.next3();
      }, 5000);


    }


    ///
    next3():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgcook.jpg", true);
      view.addChild(bg);

      
      
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot3.json"),1 );
      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);

      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTargetHide(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      let TextBox1= new TextBox("아래 모양을 잘 보면\n쉽게 찾을수 있을거야!");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("알았어! 찾아볼게",30 );


      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.GetTime());

      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }
} 

export {Scene2_2}
