import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import { Ease, ease } from "pixi-ease"
import {Config} from "./Config.ts";
import { sound } from '@pixi/sound';

class EffectAnimation extends PIXI.AnimatedSprite
{
    constructor(list:PIXI.texture[], s:number =2 ) {
        super(list, true);
              
        super.anchor.set(0.5);

        //super.animationSpeed = 0.3;
        super.animationSpeed = 0.2; 

        super.loop=true;
        
        super.play();

        super.scale.x = s;
        super.scale.y = s;
     }

     public SetAni(list:PIXI.texture[] ):void
     {
        
        this.textures = list;
                          
        super.anchor.set(0.5);

        //super.animationSpeed = 0.3;
        super.animationSpeed = 0.2; 
        

        super.loop=true;
        
        super.play();

     }
    
     s:number = 0;
     nIntervId:number;

    // public Scale(delay:number =0): void 
    // {
    //     super.scale.x = 0.01;
    //     super.scale.y = 0.01;

    //     const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic', wait:delay });
        
    // }

    // public ScaleLoop(): void 
    // {
    //     super.scale.x = 1;
    //     super.scale.y = 1;

    //     const eff1 = ease.add(this, { scale: 1.2  } , { reverse: true, duration: 1000, ease: 'easeInOutSine', repeat:true } );

    //     this.alpha =0;
        
    //     const eff2 = ease.add(this, { alpha: 1  } , { reverse: true, repeat:true, duration: 1000,   ease: 'easeInOutSine' });

    // }

//easeOutElastic
     public Rotate(): void 
     {
        super.rotation = 0.0;
        var THIS= this;
        this.nIntervId = setInterval(function(){

            THIS.s+=0.1;
            THIS.rotation = THIS.s;

        }, 50);
     }

     public DelayActive(t:number)
     {
        super.visible =false;
        setTimeout(() => {super.visible =true;}, t);
     }

     
     public MoveSin(speed:number=1.0, size:number=20): void 
     {
        const eff1 = ease.add(this, { y: this.y + size*2 } , { reverse: true, repeat:true, duration: 1000 / speed,  ease:"easeInOutSine"});
     }
    
     public FadeIn(time:number, delay:number=0): void 
     {
        this.alpha =0;
        const eff1 = ease.add(this, { alpha: 1  } , { reverse: false, duration: time,  wait:delay });
     }

     public FadeOut(time:number, delay:number=0): void 
     {
        this.alpha =1;
        const eff1 = ease.add(this, { alpha: 0  } , { reverse: false, duration: time,  wait:delay });
     }

     public FadeLoop(time:number=1000, delay:number=0): void 
     {
        this.alpha =0;
        const eff1 = ease.add(this, { alpha: 1  } , { reverse: true, repeat:true, duration: time,  wait:delay });
     }

     public FadeLoopCount(): void 
     {
        this.alpha =0;
        const eff1 = ease.add(this, { alpha: 1  } , { reverse: true, repeat:3, duration: 1000});
        eff1.once('complete', () => {this.alpha=1.0;  
            this.alpha=1;
            const eff2 = ease.add(this, { alpha: 1  } , { reverse: true, repeat:3, duration: 1000});
            eff2.once('complete', () => {this.FadeLoopCount();});
        }  );

     }

    public FadeInLeftRight(delay:number=0):void
    {
        var thing = new PIXI.Graphics();

        this.addChild(thing);
        thing.beginFill(0x000000);
  // 51 50
        thing.drawRect(-this.width/2-this.width, -this.height/2, this.width,this.height);

        thing.endFill();
        this.mask = thing;
        //const eff1 = ease.add(thing, { x: 51 } , { duration: 1000 , wait:delay});
        const eff1 = ease.add(thing, { x: this.width } , { duration: 1000 , wait:delay});
    }
    
    _gt:number = 0;
    public get gt():number {
      return this._gt;
    }
 
    public set gt(t:number)
    {
      this._gt = t;
      var p= this.P(t, this.points);
      this.x = p[0];
      this.y = p[1];
    }

    points:Array<number> ;
    public curve(points:Array<number>):void
    {
        this.points = points;
        this.gt = 0;
        const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: 3000, ease: 'easeInSine' });        
        
    }

    public distance(a, b):number{
        return Math.sqrt(Math.pow(a[0]-b[0], 2) + Math.pow(a[1]-b[1], 2));
    }            
              
  /**Computes the drawing/support points for the Bezier curve*/

      /**Computes factorial*/
      public fact(k):number{
          if(k==0 || k==1){
              return 1;
          }
          else{
              return k * this.fact(k-1);
          }
      }

      /**Computes Bernstain
      *@param {Integer} i - the i-th index
      *@param {Integer} n - the total number of points
      *@param {Number} t - the value of parameter t , between 0 and 1
      **/
      public B(i,n,t):number{
          //if(n < i) throw "Wrong";
          return this.fact(n) / (this.fact(i) * this.fact(n-i))* Math.pow(t, i) * Math.pow(1-t, n-i);
      }                            

      /**Computes a point's coordinates for a value of t
      *@param {Number} t - a value between o and 1
      *@param {Array} points - an {Array} of [x,y] coodinates. The initial points
      **/
      public P(t, points):Array<number>{
          var r = [0,0];
          var n = points.length/2-1;
          for(var i=0; i <= n; i++){
              r[0] += points[i*2] * this.B(i, n, t);
              r[1] += points[i*2+1] * this.B(i, n, t);
          }                
          return r;
      }
} 


export {EffectAnimation}
