import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene2_2_A extends Scene
{


    talker:EffectAnimation;
    constructor() 
    {
        super();

        this.loader.add("assets/cooker_4.json")
        .add("assets/cooker_1.json")
        .add("assets/anicooker.json")
        .load(
        ()=>{ 
            this.loading=false;

            this.DoStart();
          }
        );

    }

    DoStart():void
    {

      sound.stopAll();
      sound.play('bgm3',{ loop:true, volume:0.3 });
      sound.play('eff10',{ loop:true, volume:0.1 });

      
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgcook.jpg", true);
      view.addChild(bg);

    //   let eff2 = new Image("cook2.png");
    //   eff2.x = 179+153/2;
    //   eff2.y = 656+91/2; 
    //   eff2.FadeLoop();
    //   view.addChild(eff2);

    let target = ModelManager.instance().getData("target");

      
      
        this.talker = new EffectAnimation(this.GetTexture("assets/cooker_4.json") );
        this.talker.x = 191+437/2;
        this.talker.y = 509+408/2; 
        view.addChild(this.talker);

        if (target=="C2_2_B")
        {
          setTimeout(()=>this.talker.SetAni(this.GetTexture("assets/anicooker.json")) , 4000 );
        }else
        {
          setTimeout(()=>this.talker.SetAni(this.GetTexture("assets/cooker_1.json")) , 4000 );
        }
        


    

    //   let talker = new Image("cooker.png");
    //   talker.x = 191+437/2;
    //   talker.y = 509+408/2; 
    //   view.addChild(talker);

    //   let eff1 = new Image("cook1.png");
    //   eff1.x = 217+71/2;
    //   eff1.y = 685+57/2 - 10;
    //   eff1.MoveSin(1, 10);
    //   view.addChild(eff1);
      
    
      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;


      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      
      // 123456789012345678
      //let TextBox1= new TextBox("와! 금방 찾았네!\n겹아가리 톱니무늬 바리는\n 점토를 눌러 접어 입구를\n 두겹으로 만들어. 입구에\n 톱니무늬를 선을 새기듯이\n 그려넣고 톱니 가운데에\n 점으로 포인트를 줬지!\n 꼭 밥그릇처럼 생기지\n 않았어?");
      //let TextBox1= new TextBox(Config.GetTargetDesc(target));      

      let TextBox1= new TextBox(Config.GetTargetDesc0(target));      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;
      view.addChild(TextBox1);

      
      setTimeout(()=>
      {
        TextBox1.init(Config.GetTargetDesc1(target)); 
        let startButton = new Button("정말 멋진 토기야!",30 );

        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+93;
        
        startButton.DelayActive(TextBox1.GetTime());

        view.addChild(startButton);
      
        startButton.on('pointerup',()=>
        {
            ModelManager.instance().setData("zone","C2");
            SceneManager.instance().SetScene(SCENE.NEXTITEM); // 
        } );   

      
      },  TextBox1.GetTime());

      
    }
} 

export {Scene2_2_A}
