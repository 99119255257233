
import {useEffect} from "react"
import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";
import {ModelManager} from "../../lib/html5story/ModelManager.ts";

const Home = (({location, match}) => {

  useEffect(() =>  {
    console.log("v20220410");
    SceneManager.instance().Init( SCENE.LOGIN);
    //SceneManager.instance().Init( SCENE.ZONE3);
    
    const urlParameter = window.location.search;
    const urlParams = new URLSearchParams(urlParameter);
    let phoneNo = urlParams.get("phoneNo");

    if(phoneNo){
      localStorage.setItem("phoneNo",phoneNo);
    }
  },[])

  return (
  <>
  </>
)
})

export {Home}