import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { TextBox } from "./TextBox.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
class SceneARCheckSuccess extends Scene
{
    TextBox1:TextBox;

    constructor() 
    {
        super();
        this.loader.add("assets/hobot1.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {

      
        let  bg = new Image("bg-galaxy2.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        let title = new PIXI.Text("AR인식 성공", {
            fontFamily: "font1000",
            fontSize: 60,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            title.anchor.set(0.5);
            title.x=Config.SCREEN_WIDTH/2;
            title.y= 100 + 234/2;
            super.addChild(title);



        //this.TextBox1= new TextBox("응! 이제 본격적인\n청동시 시대로의 여행을 떠나볼까?", 30, false);
        this.TextBox1= new TextBox("응! 이제 본격적인\n청동시 시대로의 여행을 떠나볼까?", 30);
        this.TextBox1.x =Config.SCREEN_WIDTH/2;
        this.TextBox1.y = 286 + 30;
        super.addChild(this.TextBox1);

        //let cha = new Image("cha.png");
        let cha = new EffectAnimation(this.GetTexture("assets/hobot1.json"),1 );

        super.addChild(cha);
        cha.x = 500;
        cha.y = 700; 
        //cha.MoveSin();

        let startButton = new Button("시작",40 );
        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+43;
        startButton.DelayActive(this.TextBox1.GetTime());

        super.addChild(startButton);

        startButton.on('pointerup', ()=>
        {
            SceneManager.instance().SetScene(SCENE.ZONE1);
        });


        let phoneNo= ModelManager.instance().getData("phoneNo");
        if ( phoneNo=="" || phoneNo==undefined || phoneNo==null || phoneNo=="null")
        {
            const urlParameter = window.location.search;
            const urlParams = new URLSearchParams(urlParameter);
            phoneNo = urlParams.get("phoneNo");
            
            ModelManager.instance().setData("phoneNo",phoneNo);

            ModelManager.instance().postData(Config.URL_LOGIN,{phoneNo:phoneNo}).then((res)=>{
                if ( res.code == 200 )
                {
                   console.log("phoneNo!=null 2")
                   ModelManager.instance().init();

                   ModelManager.instance().setData("userID",res.data.userId);
                   ModelManager.instance().setData("token",res.data.token);
                   ModelManager.instance().setData("phoneNo",phoneNo);
                   ModelManager.instance().setData("ordering",res.data.ordering.toString() );
                   
                }

                ModelManager.instance().setItemList();
                // 
              });
        }
        // 
    }
} 

export {SceneARCheckSuccess}



