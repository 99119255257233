import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class Scene4_3 extends Scene
{

    
    constructor() 
    {
      super();
     
      this.loader.add("assets/jinju_6.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {
    sound.stopAll();
    sound.play('bgm1',{ loop:true, volume:0.3 });



      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bg4_2.jpg", true);
      view.addChild(bg);
      
      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);
      
       
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);

      
      //let TextBox1= new TextBox("좋아! 이제 고인돌을\n세울거야. 어떻게\n세우는지 한번 볼래?");      


      let TextBox1= new TextBox("좋아 이제 뚜껑돌을 올려\n고인돌을 만들어보자. 좀\n도와줄래.");

      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("알겠어!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
        ModelManager.instance().setTarget(4,3);
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }

  // 95 페이지
} 

export {Scene4_3}
