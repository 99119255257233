import {useEffect} from "react"

import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";

const ARCheckSuccess = (({location, match}) => {

  useEffect(() =>  {

    SceneManager.instance().Init( SCENE.ZONE1 );
    

  },[])

  return (
  <>
  </>
)
})

export {ARCheckSuccess}