import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import * as particles from "pixi-particles"

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';


class SceneClear extends Scene
{
    emitter:particles.Emitter;
    elapsed:number = 0;
    constructor() 
    {
        super();
	
        this.loader.add("assets/hobot3.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {


        sound.stopAll();
        sound.play('bgm4',{ loop:true, volume:0.3 });

        

        let target = ModelManager.instance().getData("target");

        let bg:Image = new Image(Config.GetBG(target), true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);



        let eff_light:Image = new Image("eff-light-circle.png");
        eff_light.x = Config.SCREEN_WIDTH/2;
        eff_light.y =130;
        eff_light.FadeLoop();
        super.addChild(eff_light);



        let eff_papaer:Image = new Image("eff-papaer.png");
        eff_papaer.x = Config.SCREEN_WIDTH/2;
        eff_papaer.y = 68 + 181/2;
        eff_papaer.FadeLoop(900,0);
        super.addChild(eff_papaer);


        let eff_star_particle_01:Image = new Image("eff-star-particle-01.png");
        eff_star_particle_01.x = 446+148/2;
        eff_star_particle_01.y = 96 + 164/2;
        eff_star_particle_01.FadeLoop(700,200);
        super.addChild(eff_star_particle_01);




        let eff_star_particle_02:Image = new Image("eff-star-particle-02.png");
        eff_star_particle_02.x = Config.SCREEN_WIDTH/2;
        eff_star_particle_02.y = 20 + 240/2;
        eff_star_particle_02.FadeLoop(500,100);
        super.addChild(eff_star_particle_02);





/////////////////


        let star_01:Image = new Image("star-02.png");
        star_01.x =138 +142/2;
        star_01.y =91+142/2; 
        //star_01.DelayActive(200);

        star_01.rotation = 40;
        star_01.scale.x =0.01;
        star_01.scale.y =0.01;
        super.addChild(star_01);

        const eff1 = ease.add(star_01, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic', wait:800 });
        setTimeout(()=>{sound.play('eff15');sound.play('eff14');}  , 1000);
       
        
        
        let star_02:Image = new Image("star-02.png");
        star_02.x = Config.SCREEN_WIDTH/2;
        star_02.y =79+144/2; 
        super.addChild(star_02);
        star_02.scale.x =0.01;
        star_02.scale.y =0.01;
        const eff2 = ease.add(star_02, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic', wait:1300 });
        setTimeout(()=>sound.play('eff15'), 1500);
        
        let star_03:Image = new Image("star-02.png");
        star_03.x = 358+142/2;
        star_03.y =91+142/2; 
        star_03.rotation = -40;
        //star_03.DelayActive(600);
        star_03.scale.x =0.01;
        star_03.scale.y =0.01;
        super.addChild(star_03);
        const eff3 = ease.add(star_03, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic', wait:1800 });

        setTimeout(()=>sound.play('eff15'), 2000);


        let falg_clear:Image = new Image("falg-clear.png");
        falg_clear.x = Config.SCREEN_WIDTH/2;
        falg_clear.y =187+150/2; 
        falg_clear.Scale();




        super.addChild(falg_clear);



        let eff_star:Image = new Image("eff-star.png");
        eff_star.x = 156+81/2;
        eff_star.y = 201 +80/2; 
        eff_star.FadeLoop();
        super.addChild( eff_star);

        let hobot = new EffectAnimation(this.GetTexture("assets/hobot3.json") ,1);

        hobot.x = 243+150;
        hobot.y = 386+150; 


        //hobot.MoveSin();
        super.addChild(hobot);


        let image_stamp:Image = new Image("image-stamp.png");
        image_stamp.x = 433+149/2;
        image_stamp.y = 360+138/2;
        //image_stamp.DelayActive(1000);
        super.addChild(image_stamp);
        image_stamp.scale.x =0.01;
        image_stamp.scale.y =0.01;

        const eff4 = ease.add(image_stamp, { scale: 1  } , { reverse: false, duration: 1000, ease: 'easeOutElastic', wait:2500 });
        
        //setTimeout(()=>sound.play('stamp'), 2500);

        let zone = ModelManager.instance().getData("zone");
        console.log("zoneclear:"+zone);
        let text = new PIXI.Text(Config.GetClearDesc(zone) ,
         {
          fontFamily: "font1000",
          fontWeight:"normal",
          fontSize: 30,
          fill: "#FFFFFF",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
       });
     
      text.x = Config.SCREEN_WIDTH/2;
      text.y = 730+120/2;

      text.anchor.set(0.5);
      super.addChild(text);
     
        let startButton = new Button(  Config.GetClearButton(zone) );

        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 914;
      
        startButton.on('pointerup',function()
        {
            
            var dic = {};
            dic['C2'] =  SCENE.ZONE2;
            dic['C3'] =  SCENE.ZONE3;
            dic['C4'] =  SCENE.ITEMLIST;
            
      
            SceneManager.instance().SetScene( dic[zone] );
      
        } );   

        super.addChild(startButton);
    }
    
} 

export {SceneClear}
