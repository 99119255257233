const GifSlider = (({showTakeButton, arSceneStart, showNotFoundPopup, showGifBox, setShowGifBox, gifSource, GifList, slug, changeGif, targetImage, gifIndex, checkTargetImage}) => {

	if (showTakeButton && arSceneStart && !showNotFoundPopup && showGifBox) {
	  return (
	    <>
	      	<img src={gifSource} className='show-gif' />

		    {
		        (() => {
		          if(GifList[slug].length > 1){
		            return (
		              <>
		                {/*
		                	<button onClick={changeGif} id='prev' className='change-gif-button prev'>&lt;&lt;</button>
			                <button onClick={changeGif} id='next' className='change-gif-button next'>&gt;&gt;</button>
			            */}

		                {<ul className="slider-indicators">
			                {GifList[slug].map((Gif,i) => {
			                	return (<li onClick={()=> {
			                		changeGif(i)
			                	}} key={i} className={"slider-indicator " + (i == gifIndex?'active':'')}></li>)
			                })}
		                </ul>}
		              </>
		            )
		          }
		        })()
		    }

            {
              (() => {
              	if(GifList[slug].length > 1 && (gifIndex != (GifList[slug].length - 1)) ){
              	  return (
              	    <>
              	      <button onClick={() => {
              	      	changeGif(gifIndex+1)
              	      }} className='take-it-button next' >다음</button>
              	    </>
              	  )
              	} else {
                  return (
                    <>
                      <button onClick={checkTargetImage} className='take-it-button' >확인</button>
                    </>
                  )
                }
              })()
            }
	    </>
	  )
	} else {
	  return false
	}
})

export {GifSlider}