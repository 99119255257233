import {useEffect} from "react"

import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";

const ARNotFound = (({location, match}) => {

  useEffect(() =>  {
    
    SceneManager.instance().Init( SCENE.ARNOTFOUND );

  },[])

  return (
  <>
  </>
)
})

export {ARNotFound}