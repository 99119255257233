import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class Scene4_2 extends Scene
{
    constructor() 
    {
      super();
      
      this.loader.add("assets/jinju_6.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );
    }

  start():void
  {
    sound.stopAll();
    sound.play('bgm1',{ loop:true, volume:0.3 });


      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bg4_2.jpg", true);
      view.addChild(bg);
       
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);

      ModelManager.instance().setTarget(4,2);
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      icon_item.FadeLoopCount();

      view.addChild(icon_item);
      
      
      //let TextBox2= new TextBoxBottom("진주","우리가 가져온 유물 있지?\n그걸 무덤 안에 놔줄거야.\n돌로 만든 무덤 근처에 가볼래?");


      let TextBox2= new TextBoxBottom("진주","정성껏 준비한 물건들을\n무덤 안에 넣어주자. 돌로\n만든 무덤 앞으로 가볼래?");


      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);

      let startButton = new Button("아, 그렇구나! 알겠어!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox2.GetTime());
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }

  // 95 페이지



} 

export {Scene4_2}
