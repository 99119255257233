import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';

class Scene3_2 extends Scene
{
    constructor() 
    {
        super();

        this.loader.add("assets/jinju_8.json")
        .add("assets/jinju_3.json")
        .add("assets/jinju_6.json")
        .add("assets/hobot4.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {

      sound.stopAll();
      sound.play('bgm3',{ loop:true, volume:0.3 });



      
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork2.jpg", true);
      view.addChild(bg);

      
      
    
      
  let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );


      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 


      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);


      
      // 123456789012345678
      let TextBox1= new TextBox("진주의 옥공방에 가볼까?\n진주가 만드는 장신구들도\n 구경할 겸 말이야!");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("그래 얼른 가보자",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next1();
      } );   
    }

    next1():void
    {
      
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgokwork.jpg", true);
      view.addChild(bg);

      
      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);


      let talker = new EffectAnimation( this.GetTexture("assets/jinju_8.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);



      let TextBox1= new TextBox("안녕? 처음 보는 친구네?");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);
      

      let startButton = new Button("네가 이 옥공방의 주인이라며?",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.GetTime());
      view.addChild(startButton);
    

      let index:number=0;
      startButton.on('pointerup',()=>
      {
        index++;
        if (index==1)
        {
          talker.SetAni( this.GetTexture("assets/jinju_3.json") );

          //TextBox1.init("응! 옥 좀 구경해볼래?\n내가 지금 대평이네 부모님 장례식 갈\n준비중이라 좀 바빠서…\n근데 아까부터 옥 가공할 때 쓰는\n숫돌이 안보이네.");

          //TextBox1.init("응! 옥 좀 구경해볼래?\n지난 밤 침략으로 대평이네\n부모님이 돌아가셨어.\n장례식을 준비해야 하는데\n근데 아까부터 옥 가공할 때 쓰는\n숫돌이 안보이네.");

          TextBox1.init("응! 장신구 좀 구경해볼래?\n지난 밤 침략으로 대평이네\n부모님이 돌아가셨어.\n장례식을 준비해야할 것 같아.\n근데 아까부터 옥을 가공할 때 쓰는\n숫돌이 안보이네.");
          

          startButton.SetText("숫돌?",30);
          startButton.DelayActive(TextBox1.GetTime());

        }else
        {
          super.removeChild(view);
          this.next3();
        }

      } );   
    }
    
    next3():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgokwork.jpg", true);
      view.addChild(bg);
      
      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);

      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);


      ModelManager.instance().setTarget(3,2);
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;
      icon_item.FadeLoopCount();

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      view.addChild(icon_item);
      

      let TextBox2= new TextBoxBottom("진주","숫돌에 대해 알고 싶니?\n같이 찾아주면 알려줄게!");
      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);

      let startButton = new Button("좋아 찾아볼게!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(3500);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 

      } );   
    }
   
} 

export {Scene3_2}
