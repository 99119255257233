import {BrowserRouter, Routes, Route} from "react-router-dom"
import {useState} from 'react'

// import {COLORS} from './lib/colors'
// import {MaterialUIApp} from './lib/material-ui-components'
// import {appBase} from './lib/routes'
import {Home} from './pages/home/home'
import {ARCheckFail} from './pages/home/ARCheckFail'
import {ARCheckSuccess} from './pages/home/ARCheckSuccess'
import {ARFind} from './pages/home/ARFind'
import {ARTest} from './pages/home/ARTest'
import {ARNotFound} from './pages/home/ARNotFound'
import {NotFound} from './pages/not-found/notfound'
import {Scene} from './pages/scene/scene'
import {TestAR} from './pages/test-ar/test-ar'
import {Result} from './pages/result/result'
import './App.css';

function App() {
  const base = '';

   const [showTakeButton, setShowTakeButton] = useState(false)
   const [arSceneStart, setArSceneStart] = useState(false)
   const [showNotFoundPopup, setShowNotFoundPopup] = useState(false)
   const [showNotFoundPopupHintTooltip, setShowNotFoundPopupHintTooltip] = useState(false)
   const [targetImage, setTargetImage] = useState(null)
   const [showGifBox, setShowGifBox] = useState(false)
   const [showDragDropBox, setShowDragDropBox] = useState(false)

  return (
      <BrowserRouter>
        <Routes>
          <Route exact path={`${base}`} element={<Home />} />
          <Route exact path={`${base}/ARCheckFail/`} element={<ARCheckFail />} />
          <Route exact path={`${base}/ARCheckSuccess/`} element={<ARCheckSuccess />} />
          <Route exact path={`${base}/ARFind/`} element={<ARFind />} />
          <Route exact path={`${base}/ARTest/`} element={<ARTest />} />
          <Route exact path={`${base}/ARNotFound/`} element={<ARNotFound />} />
          <Route exact path={`${base}/test-ar`} element={<TestAR 
               showTakeButton={showTakeButton} 
               setShowTakeButton={setShowTakeButton}
               targetImage={targetImage} 
               setTargetImage={setTargetImage}
               arSceneStart={arSceneStart}
               setArSceneStart={setArSceneStart}
               showNotFoundPopup={showNotFoundPopup}
               setShowNotFoundPopup={setShowNotFoundPopup}
               showNotFoundPopupHintTooltip={showNotFoundPopupHintTooltip}
               setShowNotFoundPopupHintTooltip={setShowNotFoundPopupHintTooltip}
             />}/>
          <Route exact path={`${base}/scene/:slug`} element={<Scene 
               showTakeButton={showTakeButton} 
               setShowTakeButton={setShowTakeButton}
               targetImage={targetImage} 
               setTargetImage={setTargetImage}
               arSceneStart={arSceneStart}
               setArSceneStart={setArSceneStart}
               showNotFoundPopup={showNotFoundPopup}
               setShowNotFoundPopup={setShowNotFoundPopup}
               showNotFoundPopupHintTooltip={showNotFoundPopupHintTooltip}
               setShowNotFoundPopupHintTooltip={setShowNotFoundPopupHintTooltip}
               showGifBox={showGifBox}
               setShowGifBox={setShowGifBox}
               showDragDropBox={showDragDropBox}
               setShowDragDropBox={setShowDragDropBox}
             />}/>

          <Route exact path={`${base}/result/:slug`} element={<Result />} />
          
          <Route element={<NotFound />} />
        </Routes>
      </BrowserRouter>
  )
}

export default App;
