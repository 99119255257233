import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {PopupErrorItem} from "./PopupErrorItem.ts";
import { sound } from '@pixi/sound';
class SceneARNotFound extends Scene
{
    public image : Image ;
    public dandiimage : Image ;

    public TextBox1 : TextBox ;

    

    public index:number = 0;
    public startButton:Button;
    constructor() 
    {
        super();
        let target = ModelManager.instance().getData("target");
        let errortarget = ModelManager.instance().getData("errortarget");

        errortarget="C2_2_A";

        let image = new Image("bg-03.jpg", true);
        super.addChild(image);

        let bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/rect_box.png"), 70, 70, 70, 70);
        bg.width = 504;
        bg.height = 356;
        
        bg.x = Config.SCREEN_WIDTH / 2 - 504/2;
        bg.y = 300 ;
        
        super.addChild(bg);

        // let icon_x = new Image("icon-x.png");
        // icon_x.y=358;

        // super.addChild(icon_x);

        let textArea = new PIXI.Text("찾고있는 유물이\n아니에요!", {
            fontFamily: "font1000",
            fontSize: 36,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            textArea.anchor.set(0.5);
            textArea.x= Config.SCREEN_WIDTH / 2;
            textArea.y= 470;
            super.addChild(textArea);
        {
          
        let startButton = new Image("btn-close-not.png");
          startButton.x = Config.SCREEN_WIDTH / 2 + 200;
          startButton.y = 350;

          startButton.interactive = true;
          startButton.buttonMode = true;
        
          //startButton.on('pointerdown', this.onClickDown);
          //startButton.on('pointerup', this.onClickUp);
          //startButton.on('pointerupoutside', this.onClickUp);

          startButton.on('pointerup',()=>
          {
            
            SceneManager.instance().goURL("/scene/"+target);

          } );   
        
          super.addChild(startButton);
        }

        
                
         let startButton = new Button("이 유물은 뭘까요?",30);
          startButton.x = Config.SCREEN_WIDTH / 2;
          startButton.y = 750;
          startButton.on('pointerup',()=>
          {
            let popupErrorItem =new  PopupErrorItem(this, errortarget);
            super.addChild(popupErrorItem);
            
            
            
          } );   
        
          super.addChild(startButton);
    }
    
    
} 

export {SceneARNotFound}


