import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { TextBox } from "./TextBox.ts";
import { PopupErrorZone } from "./PopupErrorZone.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';

class SceneZone1 extends Scene
{
    view1:PIXI.Container;
    view2:PIXI.Container; // 암호
    view3:PIXI.Container; // 패스워드
    TextBox1:TextBox;
    nIntervId:number;
    nIntervIdToggle:number;
    constructor() 
    {
        super();
				
    this.loader.add("assets/hobot4.json")
  .load(
  ()=>{ 
      this.loading=false;
      this.start();
  }
  );

}

start():void
{

        // let phoneNo= ModelManager.instance().getData("phoneNo");
        // if ( phoneNo=="" || phoneNo==undefined || phoneNo==null || phoneNo=="null")
        // {
        //     const urlParameter = window.location.search;
        //     const urlParams = new URLSearchParams(urlParameter);
        //     phoneNo = urlParams.get("phoneNo");
            
        //     ModelManager.instance().setData("phoneNo",phoneNo);

        // }

        const urlParameter = window.location.search;
            const urlParams = new URLSearchParams(urlParameter);
            let phoneNo = urlParams.get("phoneNo");
            
            ModelManager.instance().setData("phoneNo",phoneNo);

        
// 강제 로그인 추가
        ModelManager.instance().postData(Config.URL_LOGIN,{phoneNo:phoneNo}).then((res)=>{
          if ( res.code == 200 )
          {
             console.log("phoneNo!=null 2");
             ModelManager.instance().init();

             ModelManager.instance().setData("userID",res.data.userId);
             ModelManager.instance().setData("token",res.data.token);
             ModelManager.instance().setData("phoneNo",phoneNo);
             ModelManager.instance().setData("ordering",res.data.ordering.toString() );
             
          }
          ModelManager.instance().setItemList();
          // 
        });




        let  bg = new Image("zone2bg.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        this.view1 = new PIXI.Container();
        this.view2 = new PIXI.Container();
        this.view3 = new PIXI.Container();
        this.view2.visible = false;
        this.view3.visible = false;

        super.addChild(this.view1);
        super.addChild(this.view2);
        super.addChild(this.view3);
        
      
        this.TextBox1= new TextBox("청동기 시대라고 알고있니?\n나와 함께라면 청동기 시대를\n직접 여행할수 있어.\n같이 가볼까?", 30);
        this.TextBox1.x = Config.SCREEN_WIDTH/2;
        this.TextBox1.y = 286 + 30+100;
        this.view1.addChild(this.TextBox1);


        
        let cha = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );

        this.view1.addChild(cha);
        cha.x = 500;
        cha.y = 700; 
        //cha.MoveSin();

        //////////////////////////

        //let map_dummy=new Image("map1.png");
        let map_dummy=new Image("mapbg.png");
        

        map_dummy.x = Config.SCREEN_WIDTH/2;
        map_dummy.y = 483 + 362/2; 
        
        this.view2.addChild(map_dummy);
 

        let map_dummy2=new Image("image-1-f.png");
        map_dummy2.x = Config.SCREEN_WIDTH/2+15;;
        map_dummy2.y =483 + 362/2-2; 
        map_dummy2.ScaleLoop();
        
        this.view2.addChild(map_dummy2);


        // let arrow=new Image("image-arrow.png");

        // arrow.x = Config.SCREEN_WIDTH/2;
        // arrow.y = 655+ 59/2; 
        // arrow.MoveSin(1, 10);
        
        // this.view2.addChild(arrow);

        let text2 = new PIXI.Text("청동기 시대로 떠날\n타임 베슬 탑승장으로\n이동해보자!\n\n2층에 있어!", {
          fontFamily: "font1000",
          fontSize: 40,
          fill: "#ffffff",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
          });

        text2.anchor.set(0.5);
        text2.x= Config.SCREEN_WIDTH/2;
        text2.y= 250; 
        this.view2.addChild(text2);
        
        /////////
        
        //let text1 = new PIXI.Text("도착하면 옥목걸이를\n키오스크에 대야\n탑승할 수 있어!", {

          let text1 = new PIXI.Text("도착하면 키오스크에\n옥목걸이를 접촉해줘!", {
            fontFamily: "font1000",
            fontSize: 40,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            text1.anchor.set(0.5);
            text1.x= Config.SCREEN_WIDTH/2;
            text1.y= 705+50; 
            this.view3.addChild(text1);
  
  
            // 옥
          let image_01_copy=new Image("image-01-copy.png");
  
          image_01_copy.x = Config.SCREEN_WIDTH/2;
          image_01_copy.y = 349 + 305/2; 
  
          image_01_copy.MoveSin();
          
          this.view3.addChild(image_01_copy);


          if (Config.SKIP==true)
          {
            sound.play('eff4');
            let nextButton = new Button("____",30 );
            nextButton.x = 173/2;
            nextButton.y = Config.SCREEN_HEIGHT - 109/2;
            nextButton.alpha = 0;
            super.addChild(nextButton);
  
            nextButton.on('pointerup', ()=>
            {
              clearInterval(this.nIntervId);
              clearInterval(this.nIntervIdToggle);
              SceneManager.instance().SetScene(SCENE.ZONE1_1);
            }
            );   
          }

//  test
          // ModelManager.instance().postData(Config.URL_LOCATION, {rfidId:"test1234", kioskNo:"1" } ).then((res)=>{

          //   console.log("postData"+JSON.stringify(res));

          //   if ( res.code == 200 )
          //   {
          //      console.log("phoneNo!=null 2")
          //   }
          //   // 
          // });


        setTimeout( ()=>{
            this.view1.visible = false;
            this.view2.visible = true;
            this.view3.visible = false;
        
            this.nIntervIdToggle =setInterval( ()=>{ this.toggle() } , 3000);

            let kiosk_no="";

            this.nIntervId = setInterval( ()=>{ 
                ModelManager.instance().getLocation().then((res)=>{

                if ( res.code == 200 )
                {
                  if (res.data.kiosk_no=="1")
                  {
                    sound.play('eff4');
                    clearInterval(this.nIntervId);
                    clearInterval(this.nIntervIdToggle);
                    SceneManager.instance().SetScene(SCENE.ZONE1_1);

                  }else
                  {

                    if (kiosk_no!="" && kiosk_no != res.data.kiosk_no)
                    {
                        
                        let popupErrorZone = new PopupErrorZone(this);
                        super.addChild(popupErrorZone);
                     
                        // let info=new Image("zone1.jpg");
                        // info.x = Config.SCREEN_WIDTH/2;
                        // info.y = Config.SCREEN_HEIGHT/2; 
                        // super.addChild(info);

                    }

                    kiosk_no = res.data.kiosk_no;
                  }
                }


                // 
              });
            }
            , 2000);


           }, 4000);


          
    }
    
    public toggle():void
    {
      sound.play('eff6');
      this.view2.visible = !this.view2.visible;
      this.view3.visible = !this.view3.visible;
    }



} 

export {SceneZone1}
