import {useEffect} from "react"
import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";
import {ModelManager} from "../../lib/html5story/ModelManager.ts";

// localhost:3000/ARFind?item=C4_3_A
// 테스트
const ARFind = (({location, match}) => {
  
  useEffect(() =>  {
      
      let item = ModelManager.instance().getData("target");

      if (item !=null && item!="")
      {
        ModelManager.instance().postAttendance();
      }

      /// test source
      const urlParameter = window.location.search;
      const urlParams = new URLSearchParams(urlParameter);
      let itemUrl = urlParams.get("item");

      if (itemUrl!=""&&itemUrl!=null&&itemUrl!=undefined)  
      {
        ModelManager.instance().setData("lastscene",""  );
          item =itemUrl
          ModelManager.instance().setData("target",item);
          ModelManager.instance().setData("carditems","C2_2_A:C2_2_A:"+item);
      }

      // if (item=='C2_4_A')
      // {
      //   ModelManager.instance().setTarget(3,1);
      // }

      ////
      let scene = ModelManager.instance().getData("lastscene");
      console.log("scene:"+scene);
      //if ( scene=="" || SCENE[parseInt(scene)] == SCENE.TARGET )
      if ( scene=="" || SCENE[scene] == SCENE.TARGET|| parseInt(scene) ==6 )
      {
        var dic = {};
        dic['C2_1_A'] =  SCENE.SCENE2_2;
        dic['C2_2_A'] =  SCENE.SCENE2_2_A;
        dic['C2_2_B'] =  SCENE.SCENE2_2_A;
        dic['C2_3_A'] =  SCENE.SCENE2_3_A;
        dic['C2_3_B'] =  SCENE.SCENE2_3_A;
        dic['C2_3_C'] =  SCENE.SCENE2_3_A;
        dic['C2_4_A'] =  SCENE.NEXTITEM;
        dic['C3_1_A'] =  SCENE.SCENE3_1_A;
        dic['C3_1_B'] =  SCENE.SCENE3_1_A;
        dic['C3_2_A'] =  SCENE.SCENE3_2_A;
        dic['C3_3_A'] =  SCENE.SCENE3_3_A; // 옥 찾은후
        
        dic['C3_4_A'] =  SCENE.SCENE3_4_A;
        dic['C3_4_B'] =  SCENE.SCENE3_4_A;
        dic['C4_1_A'] =  SCENE.SCENE4_1_A;
        dic['C4_1_B'] =  SCENE.SCENE4_1_A;
        dic['C4_2_A'] =  SCENE.SCENE4_2_A;
        dic['C4_3_A'] =  SCENE.SCENE4_3_A;
        
        console.log("item:"+item);
        SceneManager.instance().Init( dic[item] );
  
      }else{
        console.log("re:"+item);

        if (isNaN(parseInt(scene)) )
        {

          console.log("re1:"+SCENE[scene]);
          SceneManager.instance().Init( SCENE[scene] );
        }else{

          console.log("re2:"+parseInt(scene));
          SceneManager.instance().Init( parseInt(scene) );
        }

      }

    

  },[])

  return (
  <>
  </>
)
})

export {ARFind}