import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import {TextBox2} from "./TextBox2.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class SceneItemList extends Scene
{
    constructor() 
    {
      super();

      this.loader.add("assets/hobot4.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {
    // sound.stopAll();
    // sound.play('bgm4',{ loop:true, volume:0.3 });



      ModelManager.instance().setData("zone","C4");

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("scbg.jpg", true);
      view.addChild(bg);
     
      let scroll:PIXI.Container =new PIXI.Container();
      let bg2 = new Image("scbg.jpg", true);
      bg2.x = 2260/2-320;
      bg2.scale.x = 1.2;
      scroll.addChild(bg2);
     
      view.addChild(scroll);
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );
      dandiimage.x = 386+212/2;
      dandiimage.y = 250; 
      dandiimage.scale.x=0.7;
      dandiimage.scale.y=0.7;

      view.addChild(dandiimage);

      // if ( Config.TEST == true )
      // {
      //   ModelManager.instance().setData("items","C2_2_A:C2_3_A:C3_1_A:C3_2_A:C3_4_A:C4_1_A" );
      // }

      let items:string = ModelManager.instance().getData("items");
      let list:string[]  = items.split(':');

      let contents1 = new Image("image-contents-01.png");
      contents1.x = Config.SCREEN_WIDTH/2 ;
      contents1.y= 400 ;
      view.addChild(contents1);

      contents1.FadeOut(1000,5000);

      let contents2 = new Image("image-contents-02.png");
      contents2.x =  Config.SCREEN_WIDTH/2 ;
      contents2.y= 400 ;

      contents2.FadeOut(1000,10000);
      contents2.FadeIn(1000,6000);
      

      view.addChild(contents2);

      let contents3 = new Image("image-contents-03.png");
      contents3.x =  Config.SCREEN_WIDTH/2 ;
      contents3.y= 400 ;
      contents3.FadeIn(1000,11000);
      view.addChild(contents3);

      for(var i=0;i< list.length ;i++)
      {
        let bgfile="";
        if (i<2)
        {
          bgfile="sc2.png";
        }else if (i<5)
        {

          bgfile="sc3.png";
        }else{
          bgfile="sc4.png";

        }

        let item_circle_bg = new Image(bgfile);
        
        item_circle_bg.x = (i*300)+300;
        item_circle_bg.y= 600 + (i % 2) * 100 ;
  
        scroll.addChild(item_circle_bg);
  
        let icon_item = new Image( Config.GetIconTarget(list[i]));
        icon_item.x =  (i*300)+300;
        icon_item.y= 600 + (i % 2) * 100 ;
        //icon_item.scale.x=0.77;
        //icon_item.scale.y=0.77;
        scroll.addChild(icon_item);

        let TextBox1= new TextBox2(Config.GetTitleNext(list[i]), 20);      
        TextBox1.x = (i*300)+300;
        TextBox1.y =  600 + (i % 2) * 100 +150;
  
        scroll.addChild(TextBox1);
      }

      let TextBox1= new TextBox("청동기시대에서\n우리가 만난 유물들이\n이렇게나 많아!");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 50 + 134/2;

      view.addChild(TextBox1);

      const eff1 = ease.add(scroll, { x: -(300*5) } , { reverse: false, duration: 12000,  wait:1000 });

      eff1.once('complete', () => {

        setTimeout(()=>{

          SceneManager.instance().SetScene(SCENE.END); // 
        
        }, 2000);

      }  );



      

    }

} 

export {SceneItemList}
