import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import { Image } from "./Image.ts";
import {DataManager} from "./DataManager.ts";
import { sound } from '@pixi/sound';

class Card extends PIXI.Sprite
{
    public image_eff_get:Image; // 빛
    public image_slot_check:Image ; // 배경 오픈
    public image_slot_locked:Image ; // 배경 락

    public item_dummy:Image ; // 아이템


    public image_target:Image; /// 사각형 테두리


    public icon_check_bg:Image; //
    public  image_check:Image; // 체크
    public eff_bulb_01:Image;
    public eff_bulb_02:Image;

    
    public icon_lock:Image;

    constructor(name: string, file:string, lockfile:string)
    {
        super();
        
        this.image_eff_get = new Image("image-eff-get.png");
        this.image_eff_get.x=0;
        this.image_eff_get.y=0;
        this.image_eff_get.Rotate();

        super.addChild(this.image_eff_get);

        this.image_slot_locked = new Image("image-slot-locked.png");
        
        this.image_slot_locked.x =0; 
        this.image_slot_locked.y =31; 
        super.addChild(this.image_slot_locked);

        this.image_slot_check = new Image("image-slot-check.png");
        this.image_slot_check.x =0; 
        this.image_slot_check.y =31; 
        super.addChild(this.image_slot_check);

        let image_locked_dummy:Image = new Image(lockfile);

        image_locked_dummy.x=0;
        image_locked_dummy.y=0;

        super.addChild(image_locked_dummy);


        this.item_dummy = new Image(file);
        this.item_dummy.x=0;
        this.item_dummy.y=0;


        //this.item_dummy.FadeIn(2000, 1000);
        super.addChild(this.item_dummy);

        this.image_target = new Image("image-target.png");
        this.image_target.x = 0;
        this.image_target.y = 31; 
        this.image_target.visible =false;
        super.addChild(this.image_target);



        this.icon_check_bg= new Image("icon-check-bg.png");
        this.icon_check_bg.x = 375 + 46 - 320;
        this.icon_check_bg.y = 594 + 80 - 1136/2;

        super.addChild(this.icon_check_bg);

        this.image_check = new Image("icon-check.png");
        this.image_check.x = 375 + 46 - 320;
        this.image_check.y = 594 + 80 - 1136/2;
        this.image_check.width = 51;
        this.image_check.height = 50;
        
        //this.image_check.DelayActive(500);  

        super.addChild(this.image_check);

        let text = new PIXI.Text(name, {
            fontFamily: "font1000",
            //fontWeight:"bold",
            fontSize: 25,
            fill: "#000000",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0
        });
  
        text.y = 160; 
        text.anchor.set(0.5);
  
        super.addChild(text);

        

        // this.icon_lock = new Image("icon-lock.png");
        // this.icon_lock.x=0;
        // this.icon_lock.y=0;
        
        // super.addChild(this.icon_lock);

        this.icon_lock = new Image("icon-locked.png");
        this.icon_lock.x=75;
        this.icon_lock.y=60;
        
        super.addChild(this.icon_lock);



        this.eff_bulb_01 = new Image("eff-bulb-01.png");
        this.eff_bulb_01.x =0; 
        this.eff_bulb_01.y =31; 
        this.eff_bulb_01.FadeLoop(1,0);  
        this.eff_bulb_01.visible =false;
        super.addChild(this.eff_bulb_01);
        this.eff_bulb_02 = new Image("eff-bulb-02.png");
        this.eff_bulb_02.y = 31;  
        this.eff_bulb_02.FadeLoop(1,180);  
        this.eff_bulb_02.visible =false;
        super.addChild(this.eff_bulb_02);

              
        super.anchor.set(0.5);
    }

    public open():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =true;
        this.image_slot_locked.visible =false;
        this.item_dummy.visible = true;

        this.icon_check_bg.visible = true;
        this.image_check.visible = true;
        
        this.image_target.visible =false;
        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        this.icon_lock.visible =false;

        this.scale.x = 0.8;
        this.scale.y = 0.8;
    }
    
    // 가운데 시작시 연출
    public openning():void
    {
        this.image_eff_get.visible = true;
        this.image_slot_locked.visible =true;
        this.image_slot_check.visible =true;

        this.image_slot_check.FadeIn(1000,0);

        this.item_dummy.visible = true;
        this.item_dummy.FadeIn(1000, 0);

        this.icon_check_bg.visible = true;
        this.icon_check_bg.Scale(1000);

        this.image_check.visible = true;
        this.image_check.FadeInLeftRight(2000);  


        this.image_target.visible =false;

        //this.eff_bulb_01.visible = true;
        //this.eff_bulb_02.visible =true;
        this.icon_lock.visible =false;
        this.scale.x = 1.0;
        this.scale.y = 1.0;
        
    }
    
    // 막힌 상ㅌ태
    public lock():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =false;
        this.image_slot_locked.visible =true;
        this.item_dummy.visible = false;
        this.image_target.visible =false;

        this.icon_check_bg.visible = false;
        this.image_check.visible = false;

        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        this.icon_lock.visible =true;
        this.scale.x = 0.8;
        this.scale.y = 0.8;
    }
    
    // 중아오면서 커지기
    public goTarget():void
    {
        this.image_eff_get.visible = false;
        this.image_slot_check.visible =false;
        this.image_slot_locked.visible =true;
        this.item_dummy.visible = false;
        this.image_target.visible =true;
        this.image_target.FadeIn(1000,0);

        this.icon_check_bg.visible = false;
        this.image_check.visible = false;

        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        this.icon_lock.visible =true;

        this.scale.x = 0.8;
        this.scale.y = 0.8;
        const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 1000});

        eff1.once('complete', () => {sound.play('eff4');} );
        
    }

    // 왼쪽이동하면서 작아지기
    public goOpen():void
    {
        this.image_eff_get.visible = true;
        this.image_eff_get.FadeOut(1000);
        this.image_slot_check.visible =true;
        this.image_slot_locked.visible =false;
        this.item_dummy.visible = true;

        this.icon_check_bg.visible = true;
        this.image_check.visible = true;
        
        this.image_target.visible =false;
        this.eff_bulb_01.visible =false;
        this.eff_bulb_02.visible =false;
        this.icon_lock.visible =false;

        this.scale.x = 1.0;
        this.scale.y = 1.0;
        const eff1 = ease.add(this, { scale: 0.8  } , { reverse: false, duration: 1000});
    }
    
} 


export {Card}
