import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import {Scene} from "./Scene.ts";
import {Config} from "./Config.ts";

class SceneLoading extends Scene
{
    constructor() 
    {
        super();

        let text = new PIXI.Text("Loading...", {
            fontFamily: "MaplestoryLight",
            //fontWeight:"bold",
            fontSize: 50,
            fill: "#FFFFFF",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0
        });
       
        text.anchor.set(0.5);
        text.x = Config.SCREEN_WIDTH/2;
        text.y = Config.SCREEN_HEIGHT/2;
        super.addChild(text);

        // const example = ease.add(text, {  scale: 2  } , { reverse: true, duration: 2500, ease: 'easeInOutQuad' });
        // example.on('each', () => console.log('ease updated object during frame using PIXI.Ticker.'));
        // example.once('complete', () => console.log('move ease complete.'));

    }
		
    

} 

export {SceneLoading}
