import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {ModelManager} from "./ModelManager.ts";
import { sound } from '@pixi/sound';


class Scene2_1 extends Scene
{
    public image : Image ;
    public dandiimage : EffectAnimation ;

    public TextBox1 : TextBox ;

    public textButton:PIXI.Text ;

    public index:number = 0;
    public startButton:Button;
    constructor() 
    {
        super();
			
        this.loader.add("assets/hobot2.json")
        .add("assets/hobot3.json")
        .add("assets/hobot1.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {        
        sound.stopAll();
        sound.play('bgm3',{ loop:true, volume:0.3 });
        sound.play('eff10',{ loop:true, volume:0.1 });


        this.image = new Image("city.jpg", true);
        this.image.x = Config.SCREEN_WIDTH/2;
        this.image.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(this.image);


        this.dandiimage = new EffectAnimation(this.GetTexture("assets/hobot2.json"),1 );

        this.dandiimage.x = 386+212/2;
        this.dandiimage.y = 593+234/2-100; 


        //this.dandiimage.MoveSin(1, 30);

        super.addChild(this.dandiimage);

       this.TextBox1= new TextBox("괜찮아?\n많이 안다쳤어?");
       this.TextBox1.x =Config.SCREEN_WIDTH/2;
       this.TextBox1.y = 100 + 234/2;

       super.addChild(this.TextBox1);
       
        this.startButton = new Button("응 괜찮아! 너는 괜찮아?",30 );
        this.startButton.x = Config.SCREEN_WIDTH / 2;
        this.startButton.y = 882+43;
        
      this.startButton.DelayActive(this.TextBox1.GetTime());
      
      
      
      var THIS = this;
      this.startButton.on('pointerup',function()
        {
          THIS.index++;
          if (THIS.index==1)
          {
            THIS.dandiimage.SetAni(THIS.GetTexture("assets/hobot3.json"));
            THIS.TextBox1.init("응 나는 괜찮아!");
            THIS.startButton.SetText("어휴..도대체 무슨 일이야?",30);
            THIS.startButton.DelayActive(THIS.TextBox1.GetTime());

          }else if (THIS.index==2)
          {
            THIS.dandiimage.SetAni(THIS.GetTexture("assets/hobot1.json"));


            THIS.TextBox1.init("맞게 잘 도착한 것 같은데…\n대평마을이 옆 부족에게\n침략을 당했나봐\n많이 어수선 하네…");

            THIS.startButton.SetText("그러게...",30);
            THIS.startButton.DelayActive(THIS.TextBox1.GetTime());

            //setTimeout(()=>sound.play('stamp'), 5000);

            
          }else if (THIS.index==3)
          {

            THIS.TextBox1.init("여긴 대평마을 입구야.\n처음보는 집들이 많지?\n한번 둘러볼까? ");
            THIS.startButton.SetText("가보자!",30);
            THIS.startButton.DelayActive(THIS.TextBox1.GetTime());
            
          }else{
            ModelManager.instance().setTarget(2,1); // 집모양 찾기
            SceneManager.instance().SetScene(SCENE.TARGET); // 
          }

        } );   
        
        super.addChild(this.startButton);
        
    }

} 

export {Scene2_1}
