import * as PIXI from "pixi.js";

class DataManager {

    private static _instance: DataManager = null;

    public loader:PIXI.Loader;

    public sheet:PIXI.Spritesheet;

    public static instance(): DataManager {
        if (DataManager._instance === null) {
            DataManager._instance = new DataManager();
        }
        return DataManager._instance;
    }

    constructor() {
        if (DataManager._instance) {
            throw new Error("Error: Config instead of new.");
        }
        DataManager._instance = this;

        this.loader = new PIXI.Loader(); 
        
    }

} 

export {DataManager}